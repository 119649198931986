import AccountDetailPage from '../features/account/pages/AccountDetailPage';
import AddProductPage from '../features/account/pages/AddProductPage'
import BindingConsentForm from '../features/consent/pages/BindingConsentForm';
import BindingConsentPage from '../features/consent/pages/BindingConsentPage';
import ConfirmAccountPage from '../features/account/pages/ConfirmAccountPage';
import InfoPage from '../features/register/pages/InfoPage';
import KmaWaitingPage from '../features/verification/pages/KmaWaitingPage';
import LandingPage from '../features/landing/pages/LandingPage';
import LockedPage from '../features/error_pages/pages/LockedPage';
import ManageConsentForm from '../features/consent/pages/ManageConsentForm';
import ManageConsentPage from '../features/consent/pages/ManageConsentPage';
import OpenKmaApp from '../features/redirect/pages/OpenKmaApp';
import OtpPage from '../features/verification/pages/OtpPage';
import PreSetting from '../features/setting/pages/PreSetting';
import PreStatementPage from '../features/statement/pages/preStatement';
import ReceiveOTPPage from '../features/verification/pages/ReceiveOTPPage';
import SettingPage from '../features/setting/pages/SettingPage';
import SuccessConsent from '../features/success/pages/SuccessConsent';
import SuccessPage from '../features/success/pages/SuccessPage';
import SuccessStatementPage from '../features/success/pages/SuccessStatementPage';
import SuccessSubmitAccountPage from '../features/success/pages/SuccessSubmitAccountPage';
import SuccessUnregisterPage from '../features/success/pages/SuccessUnregisterPage';
import SurveyPage from '../features/survey/pages/SurveyPage';
import SurveySettingPage from '../features/survey/pages/SurveySettingPage';
import SuspendPage from '../features/error_pages/pages/SuspendPage';
import TermsAndConditionsPage from '../features/register/pages/TermsAndConditionsPage';
import UnregisterPage from '../features/unregister/pages/UnregisterPage';
import VerifyPage from '../features/register/pages/VerifyPage';
import WelcomePage from '../features/register/pages/WelcomePage';
import AccountInfoPage from '../features/account/pages/AddAccountPage';
import SelectStatementPage from '../features/statement/pages/SelectStatementPage';
import SuccessSettingSurveyPage from '../features/success/pages/SuccessSettingPage';
import GeneralErrorPage from '../features/error_pages/pages/GeneralErrorPage';
import KmaCommonVerificationErrorPage from '../features/error_pages/pages/KmaCommonVerificationErrorPage';
import KmaRegisterVerificationErrorPage from '../features/error_pages/pages/KmaRegisterVerificationErrorPage copy';

export const routes = [
  // Registration
  { path: "/register", component: WelcomePage, exact: true },
  { path: "/terms-and-conditions", component: TermsAndConditionsPage, exact: true },
  { path: "/verify", component: VerifyPage, exact: true },
  { path: "/info", component: InfoPage, exact: true },

  // Verification Flow
  { path: "/otp", component: OtpPage, exact: true },
  { path: "/auth-kma-verification", component: KmaWaitingPage, exact: true },

  // Account Management
  { path: "/account-info", component: AccountInfoPage, exact: true },
  { path: "/confirm-account", component: ConfirmAccountPage, exact: true },
  { path: "/add-account/:account_no", component: AccountDetailPage, exact: true },
  { path: "/add-account", component: AddProductPage, exact: true },

  // Consent and Survey
  { path: "/binding-consent", component: BindingConsentPage, exact: true },
  { path: "/binding-consent-form", component: BindingConsentForm, exact: true },
  { path: "/survey", component: SurveyPage, exact: true },
  { path: "/success", component: SuccessPage, exact: true },

  // Statement Request
  { path: "/request-statement", component: PreStatementPage, exact: true },
  { path: "/select-statement", component: SelectStatementPage, exact: true },
  { path: "/success-statement", component: SuccessStatementPage, exact: true },

  // Settings
  { path: "/setting", component: SettingPage, exact: true },
  { path: "/request-setting", component: PreSetting, exact: true },

  // Verification Flow
  { path: "/receive-otp", component: ReceiveOTPPage, exact: true },

  // Mangage Account
  { path: "/success-account", component: SuccessSubmitAccountPage, exact: true },

  // Manage Consent
  { path: "/manage-consent", component: ManageConsentPage, exact: true },
  { path: "/manage-consent-form", component: ManageConsentForm, exact: true },
  { path: "/success-consent/:withdraw", component: SuccessConsent, exact: true },

  // Mangage Survey
  { path: "/setting-survey", component: SurveySettingPage, exact: true },
  { path: "/success-survey", component: SuccessSettingSurveyPage, exact: true },

  // Unregister
  { path: "/unbinding", component: UnregisterPage, exact: true },
  { path: "/success-unbinding", component: SuccessUnregisterPage, exact: true },

  // Error Pages
  { path: "/locked-register", component: LockedPage, exact: true },
  { path: "/suspend-register", component: SuspendPage, exact: true },
  { path: "/general-error", component: GeneralErrorPage, exact: true },
  { path: "/auth-register-verification-error", component: KmaRegisterVerificationErrorPage, exact: true },
  { path: "/auth-common-verification-error", component: KmaCommonVerificationErrorPage, exact: true },

  // Redirect Pages
  { path: "/redirect-kma", component: OpenKmaApp, exact: true },

  // Catch-All Route
  { path: "*", component: LandingPage, exact: true }
];