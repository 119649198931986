export const FETCH_FEATURE_FLAGS = 'FETCH_FEATURE_FLAGS';

export const fetchFeatureFlags = () => ({
  type: FETCH_FEATURE_FLAGS,
});

export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';

export const setFeatureFlags = (data: any) => ({
  type: SET_FEATURE_FLAGS,
  payload: data,
});
