import React from 'react';
import Dot from './Dot';

interface SpinnerProps {
    isVisible?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ isVisible = true }) => {
    if (!isVisible) return null;

    const loaderWrapperStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(34, 34, 34, 1)',
        opacity: 0.75,
        zIndex: 1000,
    };

    return (
        <div style={loaderWrapperStyle}>
            <Dot animationDelay="0s" />
            <Dot animationDelay="0.2s" />
            <Dot animationDelay="0.4s" />
        </div>
    );
};

export default Spinner;
