import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import { SET_FEATURE_FLAGS } from '../actions/featureFlagsAction';

export interface FeatureFlagState {
  enableRegistrationConsentManagement: boolean;
  enableRegistrationKma: boolean;
  enableSettingConsentManagement: boolean;
  enableSettingKma: boolean;
}

export interface FeatureFlagAction {
  type: string;
  payload: FeatureFlagState;
}

const initialStateFeatureFlags: FeatureFlagState = {
  enableRegistrationConsentManagement: false,
  enableRegistrationKma: false,
  enableSettingConsentManagement: false,
  enableSettingKma: false,
};

export const featureFlagReducer = (
  state: FeatureFlagState = initialStateFeatureFlags,
  action: FeatureFlagAction
): FeatureFlagState => {
  switch (action.type) {
    case SET_FEATURE_FLAGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const selectFeatureFlags = (state: RootState) => state.featureFlags;

export const featureFlagSelector = createSelector(
  [selectFeatureFlags],
  (featureFlags) => ({
    enableRegistrationConsentManagement: featureFlags.enableRegistrationConsentManagement,
    enableRegistrationKma: featureFlags.enableRegistrationKma,
    enableSettingConsentManagement: featureFlags.enableSettingConsentManagement,
    enableSettingKma: featureFlags.enableSettingKma,
  })
);
