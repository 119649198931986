import { useEffect, useState, useRef, ChangeEvent } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title, Description } from '../../../components/common/Texts';
import { useLocation, useNavigate } from 'react-router-dom';
import PopupAlert from '../../../components/common/popup/PopUpAlert';
import Spinner from '../../../components/common/Spinner';
import Colors from '../../../assets/styles/Colors';
import PopupValid from '../../../components/common/popup/PopupValid';
import ScreenAlert from '../../../components/common/ScreenAlert';
import { useSelector, useDispatch } from 'react-redux';
import { registerSelector } from '../../../reducers/registerReducer';
import { RootState } from '../../../reducers/rootReducer';
import { generateOtp, verifyOtp } from '../../../actions/registerAction';
import { safeNavigate } from '../../../utils/navigation';
import { Fonts } from '../../../assets/styles/Fonts';
import { isOtpExpired } from '../../../utils/isExpired';
import { pushClickButtonLayer } from '../../../utils/dataLayers/otp_layer';
import { logEvent } from '../../../utils/dataLayers/analytics';

const OtpPage: React.FC = () => {
    const dispatch = useDispatch()
    const { mobileNumber, otpRef, otpExpirationTime, isMaxAttempOtp } = useSelector((state: RootState) => registerSelector(state));
    const [timeRemaining, setTimeRemaining] = useState<{ minutes: number; seconds: number } | null>(null);

    const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
    const [actualOtp, setActualOtp] = useState<string[]>(new Array(6).fill(""));
    const [oldOtpIdx, setOldOtpIdx] = useState<number>(-1);
    const [isOtpValid, setIsOtpValid] = useState<boolean>(false);
    const [isKeyboard, setIsKeyboard] = useState<boolean>(false);

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const requestStatementByOTP = JSON.parse(sessionStorage.getItem('requestStatementByOTP') || 'false');

    const flow = sessionStorage.getItem('flow');

    const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;

        if (value.length === 6) {
            if (/^\d+$/.test(value)) {
                const digits = value.split('');
                let newOtp = [...otp];
                let newActualOtp = [...actualOtp];

                digits.forEach((digit, idx) => {
                    if (index + idx < newOtp.length) {
                        newOtp[index + idx] = digit;
                        newActualOtp[index + idx] = digit;
                    }
                });

                setOtp(newOtp);
                setActualOtp(newActualOtp);

                const allFilled = newActualOtp.every((digit) => digit !== "");
                if (allFilled) {
                    setOtp(newOtp.map(() => "•"));
                } else {
                    setOtp(newOtp.map((value) => (value ? "•" : "")));
                }

                const nextInputIndex = index + digits.length;
                if (nextInputIndex < newOtp.length) {
                    inputRefs.current[nextInputIndex]?.focus();
                } else {
                    inputRefs.current[newOtp.length - 1]?.focus();
                }

                e.preventDefault();
            }
        } else if (!isNaN(Number(value)) && value.length <= 1) {
            let newOtp = [...otp];
            let newActualOtp = [...actualOtp];

            newActualOtp[index] = value;
            newOtp[index] = value;

            setOtp(newOtp);
            setActualOtp(newActualOtp);

            const allFilled = newActualOtp.every((digit) => digit !== "");

            setTimeout(() => {
                if (allFilled || (actualOtp[index] === "")) {
                    setOtp((prevOtp) => {
                        const updatedOtp = [...prevOtp];
                        if (updatedOtp[index] !== "") {
                            updatedOtp[index] = "•";
                        }
                        if (updatedOtp[oldOtpIdx] !== "") {
                            updatedOtp[oldOtpIdx] = "•";
                        }
                        return updatedOtp;
                    });
                }
            }, 500);
            if (index < 5 && value) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleBackspace = (index: number) => {
        const newOtp = [...otp];
        const newActualOtp = [...actualOtp];
        newOtp[index] = '';
        newActualOtp[index] = '';
        setOtp(newOtp);
        setActualOtp(newActualOtp);
        if (index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const calculateTimeRemaining = (expirationTime: string): { minutes: number; seconds: number } => {
        const now = new Date();
        const expiryDate = new Date(expirationTime);
        const difference = expiryDate.getTime() - now.getTime();

        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { minutes, seconds };
    };


    useEffect(() => {
        if (otpExpirationTime) {
            const updateTimer = () => {
                const { minutes, seconds } = calculateTimeRemaining(otpExpirationTime);
                setTimeRemaining({ minutes, seconds });

                if (minutes < 0 || (minutes === 0 && seconds <= 0)) {
                    setTimeRemaining(null); // Or any action to perform when time is up
                    clearInterval(timerInterval);
                }
            };

            updateTimer();

            const timerInterval = setInterval(updateTimer, 1000);

            return () => clearInterval(timerInterval);
        }
    }, [otpExpirationTime]);

    const adjustHeight = () => {
        const height = window.innerHeight;
        if (height < 600) {
            setIsKeyboard(true)
        } else {
            setIsKeyboard(false)
        }
    };

    useEffect(() => {
        window.addEventListener('resize', adjustHeight);
        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, []);

    useEffect(() => {
        const allFilled = otp.every((digit) => digit !== "");
        setIsOtpValid(allFilled);
    }, [otp]);


    const handleResendOTP = () => {
        if (requestStatementByOTP) {
            dispatch(generateOtp({ mobileNumber: mobileNumber, isResend: true }))
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_service_otp',
                action: 'click_button',
                label: 'request_otp'
            })
        } else {
            sessionStorage.setItem('flow', 'register');
            dispatch(generateOtp({ mobileNumber: '', isResend: true }))
            pushClickButtonLayer('request_otp');
        }
    };

    const goToNextPage = () => {
        let otpCode = actualOtp.join("");
        setOtp(new Array(6).fill(""));
        dispatch(verifyOtp({ otpRef, otpCode }))
        if (flow === 'register') {
            pushClickButtonLayer('confirm_otp');
        } else {
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_service_otp',
                action: 'click_button',
                label: 'confirm_otp'
            })
        }
    };

    const goToPreviousPage = () => {
        if (flow === 'register') {
            safeNavigate('/info');
            pushClickButtonLayer('back');
        } else {
            safeNavigate('/receive-otp');
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_service_otp',
                action: 'click_button',
                label: 'back'
            })
        }
    }

    return (
        <PageProvider>
            <Title>ยืนยันด้วย OTP</Title>
            <div style={{ marginTop: 24 }} />
            <div style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                }}>
                    <Description style={{ fontFamily: Fonts.Thongterm_Bold, color: Colors.MAIN_TEXT_COLOR }}>
                        กรุณากรอกรหัส OTP ที่ส่งไปยัง <br />
                        {mobileNumber ? `XXX-XXX-${mobileNumber.slice(6, 10)}` : 'XXX-XXX-XXXX'}
                    </Description>
                    <Description>
                        รหัสจะหมดอายุใน 5 นาที <br />
                        อ้างอิง: {otpRef}
                    </Description>
                </div>
                <div style={{
                    display: 'flex',
                    gap: '8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {otp.map((data, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength={6}
                            value={data}
                            ref={(el) => inputRefs.current[index] = el}
                            onChange={e => handleOTPChange(e, index)}
                            style={{
                                width: '48px',
                                height: '48px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                borderRadius: '8px',
                                border: `1px solid ${Colors.LIGHT_GREY}`,
                                backgroundColor: Colors.WHITE,
                                fontFamily: Fonts.Thongterm_Bold,
                                color: Colors.TEXT_BODY_PRIMARY,
                                fontSize: '20px',
                                lineHeight: '32px',
                                transition: 'border-color 0.2s',
                                outline: 'none',
                                boxSizing: 'border-box',
                                padding: '2px 8px',
                                gap: '4px',
                            }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            disabled={!timeRemaining || isMaxAttempOtp}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace' && !otp[index]) handleBackspace(index);
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    (e.target as HTMLInputElement).blur();
                                }
                            }}
                            onFocus={(e) => {
                                e.target.style.borderColor = Colors.PRIMARY_KS_YELLOW;
                                e.target.style.borderWidth = '1.5px';
                            }}
                            onBlur={(e) => {
                                e.target.style.borderColor = '#ccc';
                                e.target.style.borderWidth = '1px';
                            }}
                        />
                    ))}
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {timeRemaining && !isMaxAttempOtp ?
                        (
                            <span style={{ fontFamily: Fonts.Thongterm_Medium, color: Colors.PRIMARY_TEXT_COLOR, fontSize: '16px', lineHeight: '34px' }}>
                                {`ขอรหัส OTP ใหม่ได้ในอีก `}
                                <span style={{ fontFamily: Fonts.Thongterm_Bold, color: Colors.TEXT_BODY_SECONDARY }}>
                                    {String(timeRemaining.minutes).padStart(2, '0')}:{String(timeRemaining.seconds).padStart(2, '0')}
                                </span>
                            </span>
                        )
                        : (
                            <button style={{
                                display: 'flex',
                                padding: '4px 8px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '4px',
                                border: `1px solid ${Colors.ORANGE}`,
                                background: `${Colors.WHITE}`,
                                color: `${Colors.ORANGE}`,
                                width: '158px',
                                height: '37px'
                            }}
                                onClick={handleResendOTP}>
                                <img src={'/svg/retry.svg'} alt="resend otp" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                                <span style={{ fontFamily: Fonts.Thongterm_Medium, fontSize: '16px', lineHeight: '29px' }}>
                                    ขอรหัส OTP ใหม่
                                </span>
                            </button>
                        )}
                </div>
            </div>

            <ButtomSpan>
                {!isKeyboard && (
                    <div>
                        <Button style={{ marginBottom: 24 }} text={'ยืนยัน'} type={isOtpValid ? 0 : 1} onClick={goToNextPage} />
                        <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
                    </div>
                )}
            </ButtomSpan>

        </PageProvider >
    );
};

export default OtpPage