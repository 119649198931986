export const FETCH_TERMS_AND_CONDITIONS = 'FETCH_TERMS_AND_CONDITIONS';
export const SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS';
export const ACCEPT_TERMS_AND_CONDITIONS = 'ACCEPT_TERMS_AND_CONDITIONS';
export const SAVE_SENSITIVE_DATA = 'SAVE_SENSITIVE_DATA';
export const RESET_SENSITIVE_DATA = 'RESET_SENSITIVE_DATA';
export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const PRE_REGISTER_KMA = 'PRE_REGISTER_KMA';
export const PRE_REGISTER_OTP = 'PRE_REGISTER_OTP';
export const SET_REGISTER_REFERENCE = 'SET_REGISTER_REFERENCE';
export const CREATE_DEEP_LINK = 'CREATE_DEEP_LINK';
export const SET_AUTHENTICATION_REFERENCE = 'SET_AUTHENTICATION_REFERENCE';
export const VERIFY_DEEP_LINK = 'VERIFY_DEEP_LINK';
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const SUBMIT_REGISTER = 'SUBMIT_REGISTER';
export const SUBMIT_BANK_ACCOUNTS = 'SUBMIT_BANK_ACCOUNTS';
export const GENERATE_OTP = "GENERATE_OTP"
export const SET_OTP_REFERENCE = "SET_OTP_REFERENCE"
export const VERIFY_OTP = "VERIFY_OTP"
export const GET_CONSENT_LIST = "GET_CONSENT_LIST"
export const SET_CONSENT_LIST = "SET_CONSENT_LIST"
export const SAVE_CONSENTS_LIST = "SAVE_CONSENTS_LIST"
export const VERIFY_CUSTOMER = "VERIFY_CUSTOMER"
export const SET_DEFAULT_BANK_ACCOUNTS = "SET_DEFAULT_BANK_ACCOUNTS"
export const RESET_OTP_REFERENCE = "RESET_OTP_REFERENCE"
export const OTP_MAX_ATTEMPT_COUNT = "OTP_MAX_ATTEMPT_COUNT"
export const RESET_OTP_ATTEMPT_COUNT = 'RESET_OTP_ATTEMPT_COUNT'
export const GET_VALIDATE_CAPTCHA = "GET_VALIDATE_CAPTCHA"
export const SET_VALIDATE_CAPTCHA = "SET_VALIDATE_CAPTCHA"
export const SET_HASHED_MOBILE_NO = "SET_HASHED_MOBILE_NO"
export const RESET_STATE = "RESET_STATE"
export const STAMP_START_LIFF_STATEMENT = 'STAMP_START_LIFF_STATEMENT'

export const resetRegisterState = () => {
  return {
    type: RESET_STATE,
  }
};

export const fetchTermsAndConditions = () => ({
  type: FETCH_TERMS_AND_CONDITIONS,
});

export const setTermsAndConditions = (
  terms: string,
  id: string,
) => ({
  type: SET_TERMS_AND_CONDITIONS,
  payload: {
    terms,
    id,
  }
});

export const acceptTermsAndConditions = (id: string) => ({
  type: ACCEPT_TERMS_AND_CONDITIONS,
  payload: { id },
});

export const saveSensitiveData = (idCard: string, mobileNumber: string, referral: string) => ({
  type: SAVE_SENSITIVE_DATA,
  payload: { idCard, mobileNumber, referral },
});

export const resetSensitiveData = () => ({
  type: RESET_SENSITIVE_DATA,
});

export const saveUserInfo = (identificationNumber: string, mobileNumber: string, referral: string, deviceOS: string, otpExpirationTime: string,reCaptchaToken:string, isSkipReplace?:boolean) => ({
  type: SAVE_USER_INFO,
  payload: {
    identificationNumber,
    mobileNumber,
    referral,
    deviceOS,
    otpExpirationTime,
    reCaptchaToken,
    isSkipReplace,
  }
})

export const preRegisterKMA = (userData: { identificationNumber: string, mobileNumber: string, referral: string, deviceOS: string, isResend: boolean }) => ({
  type: PRE_REGISTER_KMA,
  payload: { userData }
})

export const preRegisterOTP = (userData: { identificationNumber: string, mobileNumber: string, referral: string, deviceOS: string, isResend: boolean }) => ({
  type: PRE_REGISTER_OTP,
  payload: { userData }
})

export const setRegisterReference = (
  registerRef: string,
  mustConfirmRegisterForReplace: boolean,
) => ({
  type: SET_REGISTER_REFERENCE,
  payload: {
    registerRef,
    mustConfirmRegisterForReplace,
  }
});

export const createDeepLink = () => {
  return {
    type: CREATE_DEEP_LINK,
  }
};

export const verifyCustomer = (mobileNumber: string, otpExpirationTime: string) => {
  return {
    type: VERIFY_CUSTOMER,
    payload: { mobileNumber, otpExpirationTime }
  }
}

export const generateOtp = (otpData: { mobileNumber: string, isResend: boolean }) => {
  return {
    type: GENERATE_OTP,
    payload: { otpData }
  }
}

export const setOtpRef = (otpRef: string, otpExpirationTime: string) => {
  return {
    type: SET_OTP_REFERENCE,
    payload: { otpRef, otpExpirationTime }
  }
}

export const resetOtpRef = () => {
  return {
    type: RESET_OTP_REFERENCE,
  };
};

export const otpMaxAttemptCount = () => {
  return {
    type: OTP_MAX_ATTEMPT_COUNT,
  };
};

export const resetOtpAttemptCount = () => {
  return {
    type: RESET_OTP_ATTEMPT_COUNT,
  };
};

export const verifyOtp = (verifyBy: { otpRef: string, otpCode: string }) => {
  return {
    type: VERIFY_OTP,
    payload: { verifyBy }
  }
}

export const setAuthenticationReference = (
  authenticationWebUri: string,
  authenticationApplicationUri: string,
  expiryDateTime: string,
  authenticationCount: number
) => ({
  type: SET_AUTHENTICATION_REFERENCE,
  payload: {
    authenticationWebUri,
    authenticationApplicationUri,
    expiryDateTime,
    authenticationCount
  }
});

export const setCountNotHaveKa = (
  authenticationWebUri: string,
  authenticationApplicationUri: string,
  expiryDateTime: string,
  authenticationCount: number
) => ({
  type: SET_AUTHENTICATION_REFERENCE,
  payload: {
    authenticationWebUri,
    authenticationApplicationUri,
    expiryDateTime,
    authenticationCount
  }
});

export const verifyDeepLink = () => ({
  type: VERIFY_DEEP_LINK,
});

export const setBankAccounts = (bankAccounts: { accountId:string, accountNumber: string; accountType: string, accountStatus: string, isNotificationEnabled: boolean }[]) => {
  return {
    type: SET_BANK_ACCOUNTS,
    payload: { bankAccounts },
  }
};

export const setDefaultBankAccounts = (bankAccounts: { accountId:string, accountNumber: string; accountType: string, accountStatus: string, isNotificationEnabled: boolean }[]) => {
  return {
    type: SET_DEFAULT_BANK_ACCOUNTS,
    payload: { bankAccounts },
  }
};

interface BankAccount {
  accountNumber: string;
  type: string;
}

export const submitRegister = (
  accounts: { accountType:string, accountNumber: string; isNotificationEnabled: boolean }[],
) => {
  return {
    type: SUBMIT_REGISTER,
    payload: { accounts }
  }
}

export const submitBankAccount = (
  accounts: { accountId:string, accountNumber: string; isNotificationEnabled: boolean }[],
) => {
  return {
    type: SUBMIT_BANK_ACCOUNTS,
    payload: { accounts }
  }
}

export const getConsentList = (excludeAccepted: boolean) => {
  return {
    type: GET_CONSENT_LIST,
    payload: excludeAccepted 
  }
}

export const setConsentList = (consent: any) => {
  return {
    type: SET_CONSENT_LIST,
    payload: { consent },
  }
};

export const saveConsentList = (consents : {id: number, action: string}[]) => ({
  type: SAVE_CONSENTS_LIST,
  payload: { consents },
});


export const getValidateCaptcha = () => {
  while(true){  
    return {
      type: GET_VALIDATE_CAPTCHA
    }
  }
}

export const setValidateCaptcha = (reCaptchaToken: string) => {
  return {
    type: SET_VALIDATE_CAPTCHA,
    payload: { reCaptchaToken }
  }
}

export const setHashedMobileNumber = (hashedMobileNumber: any) => {
  return {
    type: SET_HASHED_MOBILE_NO,
    payload: { hashedMobileNumber },
  }
};

export const stampStartLiffStatement = () => {
  return { type: STAMP_START_LIFF_STATEMENT };
};