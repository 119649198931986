interface ErrorDetail {
  title: string;
  description: string;
}

export const errorMessages: Record<string, ErrorDetail> = {
  'LKS0202': {
    title: 'ไม่สามารถส่งรหัส OTP ได้ในขณะนี้',
    description: 'เนื่องจากขอรหัส OTP เกินจำนวนครั้งที่กำหนด กรุณาลองอีกครั้งในอีก',
  },
  'LKS0203': {
    title: 'รหัส OTP หมดอายุ',
    description: 'กรุณาขอรหัส OTP ใหม่',
  },
  'LKS0204': {
    title: 'กรอก OTP ไม่ถูกต้องเกินกำหนด',
    description: 'กรุณาขอรหัส OTP ใหม่ และลองกรอกอีกครั้ง',
  },
  'LKS0205': {
    title: 'รหัส OTP ไม่ถูกต้อง',
    description: 'กรุณากรอกรหัส OTP อีกครั้ง หรือขอรหัสใหม่',
  },
  'LKS0302': {
    title: 'คุณไม่ได้สมัครใช้ krungsri app',
    description: 'คุณต้องการยืนยันตัวตนด้วยรหัสผ่านครั้งเดียว (OTP) เพื่อทำรายการต่อหรือไม่',
  },
  'LKS0303': {
    title: 'ไม่มีบัญชีที่สมัครบริการได้',
    description: 'คุณไม่มีบัญชีใน krungsri app ที่ตรงตามเงื่อนไขการใช้บริการ LINE Krungsri Simple',
  },
  'LKS0402': {
    title: 'ไม่พบข้อมูลลูกค้า',
    description: 'กรุณาตรวจสอบข้อมูลและลองอีกครั้ง',
  },
  'LKS0405': {
    title: 'สมัครต่อด้วยบัญชี LINE นี้หรือไม่',
    description: 'หากคุณยืนยันที่จะสมัครต่อ บริการ LINE Krungsri Simple ที่คุณเคยสมัคร ด้วยบัญชี LINE อื่นจะถูกยกเลิก',
  },
  'LKS0406': {
    title: 'ไม่สามารถสมัครบริการได้ในขณะนี้',
    description: 'คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาสมัครบริการอีกครั้งในอีก',
  },
  'LKS0407': {
    title: 'การสมัครบริการของคุณถูกระงับ',
    description: 'คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาติดต่อสาขาธนาคารกรุงศรี ก่อนสมัครบริการอีกครั้ง',
  },
  'LKS0502': {
    title: 'ไม่สามารถเพิ่มบัญชีได้',
    description: 'คุณมีบัญชีที่รับบริการครบ 15 รายการแล้ว กรุณาลบออก ก่อนเพิ่มบัญชีอีกครั้ง',
  },
  'LKS0503': {
    title: 'ไม่พบเลขที่บัญชี',
    description: `เลขที่บัญชีนี้ไม่ตรงกับข้อมูลลูกค้า\nหรือไม่มีในระบบ\nกรุณาตรวจสอบและลองอีกครั้ง`,
  },
  'LKS0504': {
    title: 'ประเภทบัญชีไม่รองรับ',
    description: 'บัญชีนี้ไม่สามารถใช้บริการ LINE Krungsri Simple ได้ กรุณากรอกเลขที่บัญชีอื่น',
  },
  'LKS0505': {
    title: 'มีบัญชีนี้ในระบบแล้ว',
    description: 'กรุณากรอกเลขที่บัญชีอื่น',
  },
  'LKS0506': {
    title: 'บัญชีของคุณมีสถานะไม่เคลื่อนไหว',
    description: 'กรุณาติดต่อสาขาธนาคารกรุงศรี เพื่อเปิดใช้งานบัญชีอีกครั้ง',
  },
  'LKS0603': {
    title: 'ไม่สามารถยืนยันตัวตนได้ในขณะนี้',
    description: 'คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาทำรายการอีกครั้งในอีก',
  },
  'LKS0604': {
    title: 'ไม่สามารถยืนยันตัวตนได้',
    description: 'คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาติดต่อสาขาธนาคารกรุงศรี ก่อนใช้บริการอีกครั้ง',
  },
  'LKS0605': {
    title: 'ไม่พบข้อมูลลูกค้า',
    description: 'กรุณาตรวจสอบข้อมูลและลองอีกครั้ง',
  },
};