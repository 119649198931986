export const GET_ACCOUNT_DETAIL = "GET_ACCOUNT_DETAIL"
export const SET_ACCOUNT_DETAIL = "SET_ACCOUNT_DETAIL"
export const SET_STATUS_USER = "SET_STATUS_USER"
export const GENERATE_STATEMENT = "GENERATE_STATEMENT"

export const setStatusUser = (isActive: boolean) => {
  return {
    type: SET_STATUS_USER,
    payload: { isActive }
  }
}
export const getAccountDetail = () => {
  return {
    type: GET_ACCOUNT_DETAIL
  }
}

export const setAccountDetail = (accountData: { accountNumber: string, accountType: string, email: string }) => {
  return {
    type: SET_ACCOUNT_DETAIL,
    payload: { accountData }
  }
}

export const generateStatement = (
  statementDetail: {
    language: string,
    fromBookingDateTime: string,
    toBookingDateTime: string,
    email: string,
  }) => {
  return {
    type: GENERATE_STATEMENT,
    payload: { statementDetail }
  }
}