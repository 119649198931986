import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import { SET_STATUS_USER } from '../actions/statementAction';

export interface StatementState {
    isActive: boolean;
}

export interface StatementAction {
    type: string;
    payload: {
        isActive: boolean;
    };
}

const initialStateCheck: StatementState = {
    isActive: false,
};

export const statementReducer = (
  state: StatementState = initialStateCheck,
  action: StatementAction
): StatementState => {
  switch (action.type) {
    case SET_STATUS_USER:
      return {
        ...state,
        isActive: action.payload.isActive,
      };
    default:
      return state;
  }
};

const selectStatement = (state: RootState) => state.statement;

export const statementSelector = createSelector(
    [selectStatement],
    (statement) => {
        return {
            isActive: statement.isActive,
        };
    }
);
