import { api, handleApiError } from "./apiHelper";

export const apiGetFeatureFlags = async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    try {
      const response = await api.get('/services/lks/inter/api/v1/feature-flags', {
        maxBodyLength: Infinity,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleApiError(error);
    }
  };
