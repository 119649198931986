import { call, put, take } from 'redux-saga/effects';
import { apiGenerateStatement, apiGetAccountDetail } from "../api/api";
import { showSpinner, hideSpinner } from "../components/common/AppProvider";
import { handleStatusError } from "./rootSaga";
import { safeNavigate } from '../utils/navigation';
import { GET_ACCOUNT_DETAIL, GENERATE_STATEMENT, setAccountDetail, setStatusUser } from '../actions/statementAction';
import { SagaIterator } from 'redux-saga';
import { apiValidateUserStatusFromAccountId } from '../api/validateUser';

export function* workerValidateUserStatusFromAccountId(): SagaIterator {
    try {
        const response = yield call(apiValidateUserStatusFromAccountId);
        if (response.success) {
            yield put(setStatusUser(true))
        } else {
            if (response.code === 'LKS1002') {
                yield call(safeNavigate, 'register');
            } else {
                yield call(safeNavigate, 'general-error');
            }
        }
    } catch (error) {
        yield call(handleStatusError, JSON.stringify(error));
    }
}

export function* workerGetAccountDetail(): SagaIterator {
    try {
        const response = yield call(apiGetAccountDetail);
        if (response.success) {
            yield put(setAccountDetail(response.data))
        } else {
            yield call(handleStatusError, response.code);
        }
    } catch (error) {
        yield call(handleStatusError, JSON.stringify(error));
    }
}

export function* watcherGetAccountDetail(): SagaIterator {
    while (true) {
        const action = yield take(GET_ACCOUNT_DETAIL);
        yield call(workerGetAccountDetail);
    }
}

function* workerGenerateStatement(payload: any): SagaIterator {
    const { language, fromBookingDateTime, toBookingDateTime, email } = payload.statementDetail
    try {
        yield call(showSpinner);
        const response = yield call(
            apiGenerateStatement,
            language,
            fromBookingDateTime,
            toBookingDateTime,
            email,
        );
        if (response.success) {
            yield call(safeNavigate, 'success-statement')
            yield call(hideSpinner);
        } else {
            yield call(handleStatusError, response.code);
            yield call(hideSpinner);
        }
    } catch (error) {
        yield call(handleStatusError, JSON.stringify(error));
        yield call(hideSpinner);
    }
}

export function* watcherGenerateStatement(): SagaIterator {
    while (true) {
        const action = yield take(GENERATE_STATEMENT);
        yield call(workerGenerateStatement, action.payload);
    }
}