import { safeNavigate } from '../utils/navigation';
import { call, put, take, delay, select } from 'redux-saga/effects';
import {
  ACCEPT_TERMS_AND_CONDITIONS,
  CREATE_DEEP_LINK,
  FETCH_TERMS_AND_CONDITIONS,
  SAVE_USER_INFO,
  SUBMIT_REGISTER,
  VERIFY_DEEP_LINK,
  GENERATE_OTP,
  setAuthenticationReference,
  setBankAccounts,
  setRegisterReference,
  setTermsAndConditions,
  setOtpRef,
  saveSensitiveData,
  VERIFY_OTP,
  GET_CONSENT_LIST,
  setConsentList,
  SAVE_CONSENTS_LIST,
  VERIFY_CUSTOMER,
  resetOtpAttemptCount,
  resetOtpRef,
  otpMaxAttemptCount,
  resetSensitiveData,
  GET_VALIDATE_CAPTCHA,
  setDefaultBankAccounts,
  resetRegisterState,
  SUBMIT_BANK_ACCOUNTS
} from '../actions/registerAction';
import {
  apiGetTermsAndConditions,
  apiAcceptTermsAndConditions,
  apiSaveUserInfo,
  apiCreateDeepLink,
  apiVerifyDeepLink,
  apiSubmitRegister,
  apiGenerateOtp,
  apiVerifyOtp,
  apiGenerateOtpCommon,
  apiVerifyOtpCommon,
  apiGetSurveysList,
  apiGetConsentsList,
  apiSaveConsentsList,
  apiGetBankAccount,
  apiSubmitBankAccount,
  apiKmaRequest,
  apiKmaVerify,
  apiVerifyCustomer,
  apiCheckOtpAvailable,
  apiGetValidateCaptcha,
} from '../api/api';
import { showSpinner, hideSpinner, showPopupVerify, showPopupAlert, hidePopupAlert, hidePopupVerify, hidePopupValid, showPopupValid } from '../components/common/AppProvider';
import { handleStatusError, sendOfflineEventsToGA } from './rootSaga';
import { errorMessages } from '../utils/errorCode';
import { GET_BANK_ACCOUNT } from '../actions/settingAction';
import { logEvent } from '../utils/dataLayers/analytics';
import { calculateTimeToUnlock, isOtpExpired } from '../utils/isExpired';
import { registerSelector } from '../reducers/registerReducer';
import { pushEvent10, pushEvent11, pushEvent14 } from '../utils/dataLayers/before_register_layer';
import { SET_ONLINE } from '../actions/networkAction';
import { workerGetAccountDetail } from './statementSaga';
import { pushEvent9 } from '../utils/dataLayers/register_after_otp_layer';
import { purpose } from '../utils/purpose';
import { workerVerifyCustomerCommonFlow } from './settingSaga';
import { pushOkButton, pushOtpErrorPage } from '../utils/dataLayers/otp_layer';
import { featureFlagSelector } from '../reducers/featureFlagReducer';
import { SagaIterator } from 'redux-saga';

function* workerFetchTermsAndConditions(): SagaIterator {
  try {
    const response = yield call(apiGetTermsAndConditions);
    if (response.success) {
      yield put(setTermsAndConditions(
        response.data.content,
        response.data.id,
      ));
    } else {
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherFetchTermsAndConditions(): SagaIterator {
  while (true) {
    const action = yield take(FETCH_TERMS_AND_CONDITIONS);
    yield call(workerFetchTermsAndConditions);
  }
}

function* workerAcceptTermsAndConditions(payload: any): SagaIterator {
  try {
    yield call(showSpinner);
    const response = yield call(apiAcceptTermsAndConditions, payload.id);
    if (response.success) {
      yield call(safeNavigate, 'verify')
    } else {
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherAcceptTermsAndConditions(): SagaIterator {
  while (true) {
    const action = yield take(ACCEPT_TERMS_AND_CONDITIONS);
    yield call(workerAcceptTermsAndConditions, action.payload);
  }
}

export function* workerSaveUserInfo(payload: any): SagaIterator {
  try {
    const { identificationNumber, mobileNumber, referral, deviceOS, otpExpirationTime, reCaptchaToken, isSkipReplace } = payload
    const isHaveKMA = JSON.parse(sessionStorage.getItem('isHaveKMA') || 'false')
    yield call(showSpinner);
    yield put(saveSensitiveData(identificationNumber, mobileNumber, referral));
    const response = yield call(
      apiSaveUserInfo,
      identificationNumber,
      mobileNumber,
      referral,
      deviceOS,
      reCaptchaToken
    );
    if (response.success) {
      localStorage.setItem('registerRef', response.data.registerRef);
      localStorage.setItem('hashedMobileNumber', response.data.hashedMobileNumber);
      yield put(setRegisterReference(
        response.data.registerRef,
        response.data.mustConfirmRegisterForReplace,
      ));
      if (response.data.mustConfirmRegisterForReplace && !isSkipReplace) {
        const { title, description } = errorMessages["LKS0405"];

        const getWantToGoNext = () => {
          return new Promise((resolve) => {
            showPopupVerify({
              title: title,
              description: description,
              primaryButtonText: 'สมัครต่อ',
              primaryButtonClick: () => {
                hideSpinner();
                hidePopupVerify();
                resolve(true);
                pushEvent10();
              },
              secondaryButtonClick: () => {
                hideSpinner();
                hidePopupVerify();
                resolve(false);
                pushEvent11('cancel_register');
              },
            });
          });
        };
        const wantToGoNext = yield call(getWantToGoNext);

        if (wantToGoNext) {
          if (isHaveKMA) {
            sessionStorage.setItem('flow', 'register');
            localStorage.setItem('wantToGoTo', 'confirm-account')
            yield call(workerCreateDeepLink);
          } else {
            sessionStorage.setItem('flow', 'register');
            yield call(workerGenerateOtp, { otpData: { otpExpirationTime: otpExpirationTime } });
          }
        }
      }
      else {
        if (isHaveKMA) {
          sessionStorage.setItem('flow', 'register');
          localStorage.setItem('wantToGoTo', 'confirm-account')
          yield call(workerCreateDeepLink);
        } else {
          sessionStorage.setItem('flow', 'register');
          yield call(workerGenerateOtp, { otpData: { otpExpirationTime: otpExpirationTime } });
        }
      }
    } else {
      yield call(hideSpinner);
      if (response.code === 'LKS0402') {
        yield put(resetSensitiveData())
        yield call(handleStatusError, response.code);
        logEvent({
          event: 'track_event',
          category: 'line_krungsri_simple_error_pop_up',
          action: 'register_error',
          label: 'customer_info_not_found'
        })
      } else if (response.code === 'LKS0406') {
        yield put(resetSensitiveData())
        const { title, description } = errorMessages["LKS0406"];
        safeNavigate('/locked-register', {
          header: 'ข้อมูลผู้สมัคร',
          iconSources: '/svg/alert.svg',
          title: title,
          desc: description,
          buttonText: 'กลับสู่ LINE',
          time: calculateTimeToUnlock(response.metadata.cooldownFinishedAt),
          onCountdownEnd: '/info'
        });
      } else if (response.code === 'LKS0407') {
        yield put(resetSensitiveData())
        safeNavigate('/suspend-register')
      } else {
        yield call(handleStatusError, response.code);
      }
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  }
}

export function* watcherSaveUserInfo(): SagaIterator {
  while (true) {
    const action = yield take(SAVE_USER_INFO);
    yield call(workerSaveUserInfo, action.payload);
  }
}

let authenticationCount = 0;

export function* workerCreateDeepLink(): SagaIterator {
  try {
    const flow = sessionStorage.getItem('flow') || 'register';
    const { otpExpirationTime } = yield select(registerSelector)
    yield call(showSpinner);
    let response;
    if (flow === 'register') {
      response = yield call(apiCreateDeepLink)
    } else {
      response = yield call(apiKmaRequest, purpose(flow))
    }
    if (response.success) {
      authenticationCount += 1;
      sessionStorage.setItem('authRef', response.data.authenticationReference);
      yield put(setAuthenticationReference(
        response.data.authenticationWebUri,
        response.data.authenticationApplicationUri,
        response.data.expiryDateTime,
        authenticationCount
      ))
    } else {
      if (response.code === 'LKS0302') {
        const logEventParams = {
          event: 'track_event',
          action: 'verify_error',
          label: 'not_register_kma_app',
        };

        switch (flow) {
          case 'register':
            pushEvent14('not_register_kma_app');
            break;
          case 'select-statement':
            logEvent({
              ...logEventParams,
              category: 'line_krungsri_simple_request_statement_error_pop_up'
            });
            break;
          case 'confirm-account':
            logEvent({
              ...logEventParams,
              category: 'line_krungsri_simple_manage_account_error_pop_up'
            });
            break;
          case 'manage-consent':
            logEvent({
              ...logEventParams,
              category: 'line_krungsri_simple_manage_consent_error_pop_up'
            });
            break;
          case 'unbinding':
            logEvent({
              ...logEventParams,
              category: 'line_krungsri_simple_unbinding_error_pop_up'
            });
            break;
          default:
            break;
        }
        const getWantToGoNext = () => {
          return new Promise((resolve) => {
            const { title, description } = errorMessages["LKS0302"];
            showPopupVerify({
              title: title,
              description: description,
              primaryButtonText: 'ยืนยันด้วย OTP',
              primaryButtonClick: () => {
                hideSpinner();
                hidePopupVerify();
                resolve(true);
                const logEventParams = {
                  event: 'track_event',
                  action: 'click_button',
                  label: 'verified_with_otp',
                  error: 'not_register_kma_app'
                };

                switch (flow) {
                  case 'register':
                    pushEvent14('not_register_kma_app');
                    break;
                  case 'select-statement':
                    logEvent({
                      ...logEventParams,
                      category: 'line_krungsri_simple_request_statement_error_pop_up'
                    });
                    break;
                  case 'confirm-account':
                    logEvent({
                      ...logEventParams,
                      category: 'line_krungsri_simple_manage_account_error_pop_up'
                    });
                    break;
                  case 'manage-consent':
                    logEvent({
                      ...logEventParams,
                      category: 'line_krungsri_simple_manage_consent_error_pop_up'
                    });
                    break;
                  case 'unbinding':
                    logEvent({
                      ...logEventParams,
                      category: 'line_krungsri_simple_unbinding_error_pop_up'
                    });
                    break;
                  default:
                    break;
                }
              },
              secondaryButtonClick: () => {
                hideSpinner();
                hidePopupVerify();
                resolve(false);

                const logEventParams = {
                  event: 'track_event',
                  action: 'click_button',
                  label: 'cancel_verified',
                  error: 'not_register_kma_app'
                };

                switch (flow) {
                  case 'register':
                    pushEvent11('not_register_kma_app');
                    break;
                  case 'select-statement':
                    logEvent({
                      ...logEventParams,
                      category: 'line_krungsri_simple_request_statement_error_pop_up'
                    });
                    break;
                  case 'confirm-account':
                    logEvent({
                      ...logEventParams,
                      category: 'line_krungsri_simple_manage_account_error_pop_up'
                    });
                    break;
                  case 'manage-consent':
                    logEvent({
                      ...logEventParams,
                      category: 'line_krungsri_simple_manage_consent_error_pop_up'
                    });
                    break;
                  case 'unbinding':
                    logEvent({
                      ...logEventParams,
                      category: 'line_krungsri_simple_unbinding_error_pop_up'
                    });
                    break;
                  default:
                    break;
                }
              },
            });

          });
        };
        const wantToGoNext = yield call(getWantToGoNext);

        if (wantToGoNext) {
          sessionStorage.setItem("isHaveKMA", JSON.stringify(false));
          if (flow === 'register') {
            yield call(workerGenerateOtp, { otpData: { otpExpirationTime: otpExpirationTime } });
          } else {
            yield put(setAuthenticationReference("", "", "", 0))
            yield call(workerVerifyCustomerCommonFlow, 'otp')
          }
        }
      } else {
        yield call(handleStatusError, response.code);
      }
    }
  } catch (error) {
    const errorInfo = {
      message: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : '',
    };
    yield call(handleStatusError, JSON.stringify(errorInfo));
  }
}

export function* watcherCreateDeepLink(): SagaIterator {
  // while (true) {
  //   yield call(workerCreateDeepLink);
  // }
}

export function* workerVerifyDeepLink(): SagaIterator {
  const POLLING_INTERVAL = 5000;
  const MAX_POLLING_ATTEMPTS = 60;
  const flow = sessionStorage.getItem("flow");
  let pollingCount = 0;

  try {
    while (pollingCount < MAX_POLLING_ATTEMPTS) {
      let response;
      if (flow === "register") {
        response = yield call(apiVerifyDeepLink);
        if (response.success) {
          const { status, accessToken } = response.data;
          if (status === "FNLD") {
            sessionStorage.setItem("verifyToken", accessToken);
            const bankAccountsWithStatus = response.data.bankAccounts.map((account: any) => ({
              accountNumber: account.accountNumber,
              accountType: account.accountType,
              isNotificationEnabled: true,
              accountStatus: "Active",
            }));
            yield put(setBankAccounts(bankAccountsWithStatus));
            yield put(setDefaultBankAccounts(bankAccountsWithStatus));
            yield call(safeNavigate, "confirm-account");
            logEvent({
              event: "track_event",
              category: "line_krungsri_simple_account",
              action: "turn_on",
              label: "notification",
            });
            break;
          } else if (status === "ACPT" || status === "PPRC") {
            pollingCount++;
            yield delay(POLLING_INTERVAL);
          } else if (status === "RJCT" || status === "EXPD") {
            yield call(safeNavigate, "auth-register-verification-error");
            break;
          }
        } else {
          yield call(hideSpinner);
          if (response.code === "LKS0303") {
            const getWantToGoNext = () => {
              return new Promise((resolve) => {
                const { title, description } = errorMessages["LKS0303"];
                showPopupAlert({
                  title: title,
                  description: description,
                  ButtonText: "ตกลง",
                  ButtonClick: () => {
                    hideSpinner();
                    hidePopupAlert();
                    resolve(true);
                  },
                });
              });
            };
            const wantToGoNext = yield call(getWantToGoNext);

            if (wantToGoNext) {
              yield call(safeNavigate, "verify");
            }
          } else {
            yield call(hideSpinner);
            yield call(safeNavigate, "auth-register-verification-error");
          }
          break;
        }
      } else {
        response = yield call(apiKmaVerify);
        if (response.success) {
          yield put(setAuthenticationReference("", "", "", 0));
          const { status, accessToken } = response.data;
          if (status === "FNLD") {
            sessionStorage.setItem("verifyToken", accessToken);
            if (flow === "confirm-account") {
              yield call(workerGetBankAccount);
            } else if (flow === "select-statement") {
              yield call(workerGetAccountDetail);
            }
            yield call(safeNavigate, `${flow}`);
            yield call(hideSpinner);
            break;
          } else if (status === "ACPT" || status === "PPRC") {
            pollingCount++;
            yield delay(POLLING_INTERVAL);
          } else if (status === "RJCT" || status === "EXPD") {
            yield call(hideSpinner);
            yield call(safeNavigate, "auth-common-verification-error");
            break;
          }
        } else {
          yield call(hideSpinner);
          yield call(safeNavigate, "auth-common-verification-error");
          break;
        }
      }
    }

    if (pollingCount >= MAX_POLLING_ATTEMPTS) {
      yield call(hideSpinner);
      if (flow === "register") {
        yield call(safeNavigate, "auth-register-verification-error");
      } else {
        yield call(safeNavigate, "auth-common-verification-error");
      }
    }
  } catch (error) {
    const errorInfo = {
      message: error instanceof Error ? error.message : "Unknown error",
      stack: error instanceof Error ? error.stack : "",
    };
    yield call(handleStatusError, JSON.stringify(errorInfo));
    yield call(hideSpinner);
  }
}

export function* watcherVerifyDeepLink(): SagaIterator {
  while (true) {
    const action = yield take(VERIFY_DEEP_LINK);
    yield call(workerVerifyDeepLink);
  }
}

function* workerVerifyCustomer(payload: any): SagaIterator {
  try {
    yield call(showSpinner);
    const response = yield call(apiVerifyCustomer, payload.mobileNumber);
    if (response.success) {
      yield call(workerGenerateOtp, { otpData: { mobileNumber: payload.mobileNumber, isResend: false, otpExpirationTime: payload.otpExpirationTime } });
    } else {
      yield call(hideSpinner);
      if (response.code === 'LKS0603') {
        yield put(resetSensitiveData())
        const { title, description } = errorMessages["LKS0603"];
        safeNavigate('/locked-register', {
          header: 'ข้อมูลผู้สมัคร',
          iconSources: '/svg/alert.svg',
          title: title,
          desc: description,
          buttonText: 'กลับสู่ LINE',
          time: calculateTimeToUnlock(response.metadata.cooldownFinishedAt),
          onCountdownEnd: '/receive-otp'
        });
      } else if (response.code === 'LKS0604') {
        yield put(resetSensitiveData())
        const { title, description } = errorMessages["LKS0604"];
        safeNavigate('/suspend-register', { header: title, description: description })
      } else if (response.code === 'LKS0605' || response.code === 'LKS0605') {
        yield put(resetSensitiveData())
        yield call(handleStatusError, response.code);
      } else {
        yield call(handleStatusError, response.code);
      }
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherVerifyCustomer(): SagaIterator {
  while (true) {
    const action = yield take(VERIFY_CUSTOMER);
    yield call(workerVerifyCustomer, action.payload);
  }
}

function* workerGenerateOtp(payload: any): SagaIterator {
  const { mobileNumber, otpExpirationTime, isResend } = payload.otpData
  const flow = sessionStorage.getItem('flow');

  if (mobileNumber) {
    yield put(saveSensitiveData('', mobileNumber, ''));
  }

  if (!isOtpExpired(otpExpirationTime)) {
    yield call(safeNavigate, 'otp');
    yield call(hideSpinner);
    return;
  }
  try {
    yield call(showSpinner);
    let response;
    if (flow === 'register') {
      response = yield call(apiGenerateOtp);
    } else {
      response = yield call(apiGenerateOtpCommon);
    }

    if (response.success) {
      if (isResend) {
        yield call(showPopupValid, { title: 'ส่งรหัส OTP ไปยังเบอร์มือถือแล้ว' })
        yield delay(2000);
        yield call(hidePopupValid)
      }
      yield put(setOtpRef(response.data.otpRef, response.data.expiredAt))
      yield put(resetOtpAttemptCount())
      yield call(safeNavigate, 'otp')
    } else {
      if (response.code === 'LKS0202') {
        const { title, description } = errorMessages['LKS0202'];
        safeNavigate('/locked-register', {
          header: 'ยืนยันด้วย OTP',
          iconSources: '/svg/alert.svg',
          title: title,
          desc: description,
          buttonText: 'กลับสู่ LINE',
          time: calculateTimeToUnlock(response.metadata.cooldownFinishedAt),
          onCountdownEnd: '/info'
        });
      } else {
        yield call(handleStatusError, response.code);
      }
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGenerateOtp(): SagaIterator {
  while (true) {
    const action = yield take(GENERATE_OTP);
    yield call(workerGenerateOtp, action.payload);
  }
}

function* workerCheckOtpAvailale(): SagaIterator {
  try {
    const response = yield call(apiCheckOtpAvailable);
    if (response.success) {
      yield call(hidePopupAlert);
      yield call(hideSpinner);
    } else {
      if (response.code === 'LKS0202') {
        yield call(hidePopupAlert);
        yield call(hideSpinner);
        const { title, description } = errorMessages['LKS0202'];
        safeNavigate('/locked-register', {
          header: 'ยืนยันด้วย OTP',
          iconSources: '/svg/alert.svg',
          title: title,
          desc: description,
          buttonText: 'กลับสู่ LINE',
          time: calculateTimeToUnlock(response.metadata.cooldownFinishedAt),
          onCountdownEnd: '/info'
        });
      } else {
        yield call(handleStatusError, response.code);
        yield call(hideSpinner);
      }
    }

  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
    yield call(hideSpinner);
  }
}

function* workerVerifyOtp(payload: any): SagaIterator {
  const { otpRef, otpCode } = payload.verifyBy
  const flow = sessionStorage.getItem('flow');

  try {
    yield call(showSpinner);
    let response;
    if (flow === 'register') {
      response = yield call(apiVerifyOtp, otpRef, otpCode)
      if (response.success) {
        sessionStorage.setItem('verifyToken', response.data.accessToken);
        sessionStorage.removeItem('isResend');
        logEvent({
          event: 'track_event',
          category: 'line_krungsri_simple_otp_pop_up',
          action: 'otp_popup',
          label: 'verified_complete',
        });
        yield call(showPopupValid, { title: 'ยืนยันด้วย OTP สำเร็จ' })
        yield delay(2000)
        yield put(resetOtpRef())
        yield call(hidePopupValid)
        yield call(safeNavigate, 'account-info')
        yield call(hideSpinner);
      } else {
        if (response.code === 'LKS0202') {
          const { title, description } = errorMessages['LKS0202'];
          safeNavigate('/locked-register', {
            header: 'ยืนยันด้วย OTP',
            iconSources: '/svg/alert.svg',
            title: title,
            desc: description,
            buttonText: 'กลับสู่ LINE',
            time: calculateTimeToUnlock(response.metadata.cooldownFinishedAt),
            onCountdownEnd: '/info'
          });
          yield call(hideSpinner);
        } else if (response.code === 'LKS0203') {
          const getWantToGoNext = () => {
            return new Promise((resolve) => {
              pushOtpErrorPage('otp_expired');
              const { title, description } = errorMessages["LKS0203"];
              showPopupAlert({
                title: title,
                description: description,
                ButtonText: 'ตกลง',
                ButtonClick: () => {
                  hideSpinner();
                  resolve(true);
                  pushOkButton('otp_expired');
                }
              });
            });
          };
          const wantToGoNext = yield call(getWantToGoNext);

          if (wantToGoNext) {
            yield call(workerCheckOtpAvailale)
          }
        } else if (response.code === 'LKS0204') {
          yield put(otpMaxAttemptCount())
          pushOtpErrorPage('incorrect_otp_over_limit');
          const getWantToGoNext = () => {
            return new Promise((resolve) => {
              const { title, description } = errorMessages["LKS0204"];
              showPopupAlert({
                title: title,
                description: description,
                ButtonText: 'ตกลง',
                ButtonClick: () => {
                  hideSpinner();
                  resolve(true);
                  pushOkButton('incorrect_otp_over_limit');
                }
              });
            });
          };
          const wantToGoNext = yield call(getWantToGoNext);

          if (wantToGoNext) {
            yield call(workerCheckOtpAvailale)
          }
        } else {
          yield call(handleStatusError, response.code);
          yield call(hideSpinner);
        }
      }
    } else {
      response = yield call(apiVerifyOtpCommon, otpRef, otpCode)
      if (response.success) {
        logEvent({
          event: 'track_event',
          category: 'line_krungsri_simple_service_otp_pop_up',
          action: 'otp_popup',
          label: 'verified_complete',
        })
        sessionStorage.setItem('verifyToken', response.data.accessToken);
        yield call(showPopupValid, { title: 'ยืนยันด้วย OTP สำเร็จ' })
        yield delay(2000)
        yield call(hidePopupValid)
        yield put(resetRegisterState())
        if (flow === 'select-statement') {
          yield call(workerGetAccountDetail)
        } else if (flow === 'confirm-account') {
          yield call(workerGetBankAccount)
        }
        sessionStorage.setItem('isFirstTimeVerifyOtp', JSON.stringify(true));
        yield call(safeNavigate, `${flow}`)
        yield call(hideSpinner);
      } else {
        yield call(hideSpinner);
        if (response.code === 'LKS0203') {
          logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_service_otp_error_pop_up',
            action: 'otp_error',
            label: 'otp_expired',
          })
          const getWantToGoNext = () => {
            return new Promise((resolve) => {
              const { title, description } = errorMessages["LKS0203"];
              showPopupAlert({
                title: title,
                description: description,
                ButtonText: 'ตกลง',
                ButtonClick: () => {
                  hideSpinner();
                  resolve(true);
                  logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_service_otp_error_pop_up',
                    action: 'click_button',
                    label: 'agree',
                    error: 'otp_expired'
                  })
                }
              });
            });
          };
          const wantToGoNext = yield call(getWantToGoNext);

          if (wantToGoNext) {
            yield call(workerCheckOtpAvailale)
          }
        } else if (response.code === 'LKS0204') {
          yield put(otpMaxAttemptCount())
          logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_service_otp_error_pop_up',
            action: 'otp_error',
            label: 'incorrect_otp_over_limit',
          })
          const getWantToGoNext = () => {
            return new Promise((resolve) => {
              const { title, description } = errorMessages["LKS0204"];
              showPopupAlert({
                title: title,
                description: description,
                ButtonText: 'ตกลง',
                ButtonClick: () => {
                  resolve(true);
                  logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_service_otp_error_pop_up',
                    action: 'click_button',
                    label: 'agree',
                    error: 'incorrect_otp_over_limit'
                  })
                }
              });
            });
          };
          const wantToGoNext = yield call(getWantToGoNext);

          if (wantToGoNext) {
            yield call(workerCheckOtpAvailale)
          }
        } else {
          yield call(handleStatusError, response.code);
        }
      }
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  }
}

export function* watcherVerifyOtp(): SagaIterator {
  while (true) {
    const action = yield take(VERIFY_OTP);
    yield call(workerVerifyOtp, action.payload);
  }
}

function* workerSubmitRegister(payload: any): SagaIterator {
  const { accounts } = payload
  const featureFlags = yield select(featureFlagSelector);
  const { enableRegistrationConsentManagement } = featureFlags;
  try {
    yield call(showSpinner);
    const response = yield call(apiSubmitRegister, accounts);
    if (response.success) {
      pushEvent9();
      localStorage.removeItem('registerRef')
      yield call(showPopupValid, { title: 'สมัครบริการสำเร็จ' })
      yield delay(2000);
      yield call(hidePopupValid)
      if (enableRegistrationConsentManagement && response.data.mustConsent) {
        yield call(workerGetConsentList, true)
      } else {
        yield call(safeNavigate, '/survey')
      }
    } else {
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherSubmitRegister(): SagaIterator {
  while (true) {
    const action = yield take(SUBMIT_REGISTER);
    yield call(workerSubmitRegister, action.payload);
  }
}

function* workerSubmitBankAccounts(payload: any): SagaIterator {
  const { accounts } = payload
  try {
    yield call(showSpinner);
    const response = yield call(apiSubmitBankAccount, accounts);
    if (response.success) {
      safeNavigate('success-account');
    } else {
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherSubmitBankAccounts(): SagaIterator {
  while (true) {
    const action = yield take(SUBMIT_BANK_ACCOUNTS);
    yield call(workerSubmitBankAccounts, action.payload);
  }
}

function* workerGetConsentList(payload: boolean): SagaIterator {
  try {
    yield call(showSpinner);;
    const flow = sessionStorage.getItem('flow');
    const response = yield call(apiGetConsentsList, payload);
    if (response.success) {
      if (response.data.data.consents.length > 0) {
        const mappedChoices = response.data.data.consents.map((consent: any, index: number) => ({
          id: consent.id,
          action: payload ? "N" : consent.action,
          name: consent.contentHtml,
          remark: consent.consentRemark,
          entity: consent.entity.nameTh,
          channel: consent.channel.nameTh,
          signedVersion: consent.signedVersion,
          updatedAt: consent.updatedAt,
          consentReference: consent.consentReference
        }));
        yield put(setConsentList(mappedChoices));
        if (flow === 'register') {
          yield call(safeNavigate, '/binding-consent')
        } else {
          yield call(safeNavigate, '/manage-consent-form')
        }
      } else {
        yield call(safeNavigate, '/survey')
      }
    } else {
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetConsentList(): SagaIterator {
  while (true) {
    const action = yield take(GET_CONSENT_LIST);
    yield call(workerGetConsentList, action.payload);
  }
}

function* workerSaveConsentsList(payload: any): SagaIterator {
  try {
    yield call(showSpinner);
    const response = yield call(apiSaveConsentsList, payload.consents);
    if (!response.success && response.message === "No response received from server") {
      yield call(handleStatusError, 'manage-consent-form_system_error_internet');
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherSaveConsentsList(): SagaIterator {
  while (true) {
    const action = yield take(SAVE_CONSENTS_LIST);
    yield call(workerSaveConsentsList, action.payload);
  }
}

export function* workerGetBankAccount(): SagaIterator {
  try {
    const response = yield call(apiGetBankAccount);
    if (response.success) {
      yield put(setDefaultBankAccounts(response.data))
      yield put(setBankAccounts(response.data))
    } else {
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  }
}

export function* watcherGetBankAccount(): SagaIterator {
  while (true) {
    const action = yield take(GET_BANK_ACCOUNT);
    yield call(workerGetBankAccount);
  }
}

export function* watcherOnlineStatus(): SagaIterator {
  while (true) {
    yield take(SET_ONLINE);
    yield call(sendOfflineEventsToGA);
  }
}

function* workerValidateCaptcha(): SagaIterator {
  try {
    yield call(showSpinner);
    const response = yield call(apiGetValidateCaptcha);
    if (response.success) {
      safeNavigate('/info', {
        isValidateCaptcha: response.data.isValidateCaptcha
      });
    } else {
      yield call(handleStatusError, 'navigationRef.current is not set');
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherValidateCaptcha(): SagaIterator {
  while (true) {
    const action = yield take(GET_VALIDATE_CAPTCHA);
    yield call(workerValidateCaptcha);
  }
}