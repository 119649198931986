import React, { CSSProperties, useEffect, useState } from 'react';

interface CalendarModalProps {
    isOpen: boolean;
    onClose: (selectedDate: Date, formattedDate: string) => void;
    initialDate?: Date | null;
    maxDate?: Date;  // New prop for "selectFrom" calendar
    minDate?: Date;  // New prop for "selectTo" calendar
}

const CalendarModal: React.FC<CalendarModalProps> = ({
    isOpen,
    onClose,
    initialDate,
    maxDate,
    minDate,
}) => {
    const today = new Date(); // Today's date
    const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()); // One year ago

    const [currentDate, setCurrentDate] = useState(initialDate || new Date());
    const [fromDate, setFromDate] = useState<Date | null>(initialDate || null);
    const [toDate, setToDate] = useState<Date | null>(null); // State for toDate

    useEffect(() => {
        if (initialDate) {
            setFromDate(initialDate);
        }
    }, [initialDate]);

    const handleSelectFromDay = (day: number) => {
        const newSelectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        if (newSelectedDate > today || newSelectedDate < oneYearAgo || (maxDate && newSelectedDate > maxDate)) {
            return; // Exit if the date is invalid
        }
        setFromDate(newSelectedDate);
    };

    const isOlderThanOneYear = (date: Date) => {
        return date < oneYearAgo;
    };

    const isCurrentMonth = (date: Date) => {
        return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth();
    };

    const handleSelectToDay = (day: number) => {
        const newSelectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        if (newSelectedDate < (fromDate || today) || newSelectedDate > today || newSelectedDate < oneYearAgo || (minDate && newSelectedDate < minDate)) {
            return; // Exit if invalid
        }
        setToDate(newSelectedDate);
    };

    const isDisabled = (currentDay: Date): boolean => {
        const isAfterMaxDate = maxDate ? currentDay > maxDate : false; // Explicitly check if maxDate is defined
        const isBeforeMinDate = minDate ? currentDay < minDate : false; // Explicitly check if minDate is defined

        return (
            currentDay > today ||  // Disable if after today
            currentDay <= oneYearAgo ||  // Disable if before one year ago
            isAfterMaxDate ||  // Disable if after maxDate
            isBeforeMinDate // Disable if before minDate
        );
    };

    const handleDone = () => {
        const selectedDate = fromDate || new Date(); // Ensure fromDate is not null
        const dateAtMidnight = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0);
        const formattedDate = selectedDate.toLocaleDateString(); // Adjust this to your formatting function
        onClose(dateAtMidnight, formattedDate); // Pass both Date and formatted string
    };

    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const daysInCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

    // Calculate days from previous month to show
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const daysInPrevMonth = new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 0).getDate();
    const prevMonthDays = [...Array(firstDayOfMonth)].map((_, index) => daysInPrevMonth - firstDayOfMonth + index + 1);

    // Calculate days from next month to show
    const remainingCells = (7 - ((prevMonthDays.length + daysInCurrentMonth) % 7)) % 7;
    const nextMonthDays = [...Array(remainingCells)].map((_, index) => index + 1);

    const isSelectedDay = (day: number, isCurrentMonth: boolean) => {
        if (!fromDate) return false;
        return (
            fromDate.getFullYear() === currentDate.getFullYear() &&
            fromDate.getMonth() === currentDate.getMonth() &&
            fromDate.getDate() === day
        );
    };

    if (!isOpen) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.calendarContainer} onClick={(e) => e.stopPropagation()}>
                {/* Month and Year Header */}
                <div style={styles.header}>
                    <span style={styles.monthYear}>
                        {`${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear() + 543}`}
                        {/* <img src={'/svg/arrowDisclosure.svg'} alt="Previous month" style={styles.miniArrowIcon} /> */}
                    </span>
                    <div style={styles.arrowButtonContainer}>
                        <button
                            style={{
                                ...styles.arrowButton,
                                ...(isOlderThanOneYear(currentDate) && styles.disabledButton),
                            }}
                            onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))}
                            disabled={isOlderThanOneYear(currentDate)} // Disable if older than 1 year ago
                        >
                            <img src={'/svg/arrowPrevious.svg'} alt="Previous month" style={styles.arrowIcon} />
                        </button>

                        <button
                            style={{
                                ...styles.arrowButton,
                                ...(isCurrentMonth(currentDate) && styles.disabledButton),
                            }}
                            onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))}
                            disabled={isCurrentMonth(currentDate)} // Disable if in the current month
                        >
                            <img src={'/svg/arrowNext.svg'} alt="Next month" style={styles.arrowIcon} />
                        </button>
                    </div>
                </div>

                {/* Days of the Week */}
                <div style={styles.daysOfWeekContainer}>
                    {daysOfWeek.map((day) => (
                        <div key={day} style={styles.dayOfWeek}>
                            {day}
                        </div>
                    ))}
                </div>

                {/* Days of the Month */}
                <div style={styles.daysContainer}>
                    {/* Previous month's days */}
                    {prevMonthDays.map((_, index) => (
                        <div
                            key={`prev-blank-${index}`}
                            style={{
                                ...styles.day,
                                visibility: 'hidden',
                            }}
                        />
                    ))}

                    {/* Current month's days */}
                    {[...Array(daysInCurrentMonth)].map((_, index) => {
                        const day = index + 1;
                        const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);

                        return (
                            <div
                                key={`current-${day}`}
                                style={{
                                    ...styles.day,
                                    ...(isSelectedDay(day, true) ? styles.selectedDay : styles.currentMonthDay),
                                    ...(isDisabled(currentDay) && styles.disabledDay),
                                }}
                                onClick={() => !isDisabled(currentDay) && handleSelectFromDay(day)}
                            >
                                {day}
                            </div>
                        );
                    })}

                    {/* Next month's days */}
                    {nextMonthDays.map((_, index) => (
                        <div
                            key={`next-blank-${index}`}
                            style={{
                                ...styles.day,
                                visibility: 'hidden',
                            }}
                        />
                    ))}
                </div>

                <div style={styles.divider} />

                {/* Reset and Done buttons */}
                <div style={styles.footer}>
                    {/* <button
                        style={styles.resetButton}
                        onClick={() => {
                            const resetDate = new Date(); // Set resetDate to today's date
                            setFromDate(resetDate); // Update selected date to today
                            setCurrentDate(resetDate);   // Update current date to today
                        }}
                    >
                        Reset
                    </button> */}
                    <button style={styles.doneButton} onClick={handleDone}>
                        Done
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles: { [key: string]: CSSProperties } = {
    day: {
        width: '44px', // Ensure width and height are the same for circular shape
        height: '44px',
        borderRadius: '50%', // Makes the days circular
        cursor: 'pointer',
        display: 'flex', // Flex to center content
        justifyContent: 'center',
        alignItems: 'center',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontSize: '20px', // Same size for all days
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '25px', // 125% for consistency
        letterSpacing: '-0.45px',
        padding: 'auto', // <-- Add padding here (adjust value as needed)
        margin: '2px',  
    },
    currentMonthDay: {
        color: 'var(--Labels-Primary, #000)', // Current month day color
    },
    otherMonthDay: {
        color: 'var(--Palette-text-placeholder_disable-color, #BBB)', // Other month day color
    },
    selectedDay: {
        backgroundColor: '#007AFF', // Selected day background
        color: '#fff', // Selected day text color
    },
    dayOfWeek: {
        display: 'flex',
        width: '44px', // Same width and height as days for consistency
        height: '44px',
        flexDirection: 'column',
        justifyContent: 'center',
        flexShrink: 0,
        color: 'var(--Labels-Tertiary, rgba(60, 60, 67, 0.30))',
        textAlign: 'center',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 590,
        letterSpacing: '0px',
    },
    arrowButtonContainer: {
        display: 'flex',
    },
    arrowButton: {
        background: 'none',
        border: 'none',
        fontSize: '18px',
        cursor: 'pointer',
        color: '#007bff', // Blue color for active arrow buttons
        opacity: 1, // Default opacity when active
        transition: 'opacity 0.3s ease', // Smooth transition when changing states
    },
    disabledButton: {
        opacity: 0.5, // Reduced opacity when disabled
        color: 'grey', // Change the color to grey when disabled
        cursor: 'not-allowed', // Show "not-allowed" cursor when disabled
    },
    arrowIcon: {
        width: '24px',
        height: '24px',
    },
    miniArrowIcon: {
        width: '11px',
        height: '14px',
        marginLeft: '4px', // Optional: Add space between text and icon
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // Set a high z-index for the overlay
    },
    calendarContainer: {
        backgroundColor: '#fff',
        padding: '14px 16px 12px 16px',
        borderRadius: '13px',
        width: '100%',
        marginRight: '7px',
        marginLeft: '7px',
        zIndex: 1001, // Set a higher z-index for the calendar container
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    monthYear: {
        fontSize: '20px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center', justifyContent: 'center',
    },
    daysOfWeekContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
    },
    daysContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
    },
    divider: {
        height: '0.5px',
        backgroundColor: '#e0e0e0',
        margin: '10px 0',
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    resetButton: {
        color: 'var(--Colors-Blue, #007AFF)',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px', // 133.333%
        letterSpacing: '-0.23px',
        backgroundColor: 'transparent', // To remove background color
        border: 'none', // To remove border
        cursor: 'pointer',
    },
    doneButton: {
        color: 'var(--Colors-Blue, #007AFF)',
        fontFeatureSettings: "'liga' off, 'clig' off",
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 590,
        lineHeight: '20px', // 133.333%
        letterSpacing: '-0.23px',
        backgroundColor: 'transparent', // To remove background color
        border: 'none', // To remove border
        cursor: 'pointer',
    },
    disabledDay: {
        color: 'var(--Palette-text-placeholder_disable-color, #BBB)', // Grey color for disabled days
        cursor: 'not-allowed', // Show "not-allowed" cursor on hover
        pointerEvents: 'none', // Prevent clicking on disabled days
        backgroundColor: 'transparent', // No background for disabled days
    },
};

export default CalendarModal;
