import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import { CHECK_PRE_SETTING_CONDITION, STAMP_START_LIFF, STORE_OLD_USER_SURVEY, UNREGISTER_USER } from '../actions/settingAction';

export interface SettingState {
    isFillConsentAllowed: boolean;
    deviceOS: string;
    oldSurvey: { id: string; name: string;}[];
    isStartLiff: boolean;
    isLoadingSuccess: boolean;
}

export interface SettingAction {
    type: string;
    payload: {
        isFillConsentAllowed: boolean;
        deviceOS: string;
        oldSurvey: { id: string; name: string;}[];
        isStartLiff: boolean;
        isLoadingSuccess: boolean;
    }
}

const initialStateCheck: SettingState = {
    oldSurvey: [],
    deviceOS: "",
    isFillConsentAllowed: false,
    isStartLiff: true,
    isLoadingSuccess: false,
};
  
export const settingReducer = (
  state: SettingState = initialStateCheck,
  action: SettingAction
): SettingState => {
  switch (action.type) {
    case CHECK_PRE_SETTING_CONDITION:
      return {
        ...state,
        isFillConsentAllowed: action.payload.isFillConsentAllowed,
        isLoadingSuccess: action.payload.isLoadingSuccess
      };
    case STORE_OLD_USER_SURVEY:
        return {
          ...state,
          oldSurvey: action.payload.oldSurvey,
        }
    case STAMP_START_LIFF:
      return {
        ...state,
        isStartLiff: false,
      }
    default:
        return state
    }
};

const selectSetting = (state: RootState) => state.setting;

export const settingSelector = createSelector(
    [selectSetting],
    (setting) => {
        return {
            isFillConsentAllowed: setting.isFillConsentAllowed,
            deviceOS: setting.deviceOS,
            oldSurvey: setting.oldSurvey,
            isStartLiff: setting.isStartLiff,
            isLoadingSuccess: setting.isLoadingSuccess,
        };
    }
);
