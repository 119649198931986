import React from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import Button from '../../../components/common/Button';
import BottomSpan from '../../../components/layout/BottomSpan';
import { useDispatch } from 'react-redux';
import { unregisterUser } from '../../../actions/settingAction';
import { safeNavigate } from '../../../utils/navigation';
import { logEvent } from '../../../utils/dataLayers/analytics';
import Colors from '../../../assets/styles/Colors';
import { Fonts } from '../../../assets/styles/Fonts';

const UnregisterPage: React.FC = () => {
    const dispatch = useDispatch();
    const goToNextPage = () => {
        logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_unbinding',
            action: 'click_button',
            label: 'confirm_unbinding',
        })
        dispatch(unregisterUser());
    };

    const goToPreviousPage = () => {
        logEvent({
            event: 'track_event',
            category: 'line_krungsri_simple_unbinding',
            action: 'click_button',
            label: 'cancel_unbinding',
        })
        safeNavigate('setting');
    };

    return (
        <PageProvider>
            <div style={{ margin: '0 auto', textAlign: 'center', paddingTop: '15vh', }}>
                <img src={'/svg/alert.svg'} alt="Popup Icon" style={{ width: '88px', height: '88px', marginBottom: '4vh', }} />
                <div style={{ color: Colors.MAIN_TEXT_COLOR, fontFamily: Fonts.Krungsri_Bold, fontSize: '24px', lineHeight: '36px', marginBottom: '8px', whiteSpace: 'pre-line', }}>
                    {'ยกเลิกการสมัครใช้บริการ\nLINE Krungsri Simple หรือไม่'}
                </div>
                <div style={{ color: Colors.PRIMARY_TEXT_COLOR, fontFamily: Fonts.Thongterm_Medium, fontSize: '16px', lineHeight: '29px', whiteSpace: 'pre-line', }}>
                    {`การยกเลิกจะทำให้คุณไม่สามารถรับแจ้งเตือน\nเงินเข้า-ออกบัญชี, เช็กยอด, ขอ Statement\nและบริการอื่น ๆ ผ่าน LINE Krungsri Simple ได้`}
                </div>
            </div>

            <BottomSpan>
                <Button text={'ยกเลิกบริการ'} onClick={() => goToNextPage()} type={0} style={{ marginBottom: 24 }} />
                <Button text={'ใช้บริการต่อ'} onClick={() => goToPreviousPage()} type={2} />
            </BottomSpan>
        </PageProvider>
    )
}

export default UnregisterPage