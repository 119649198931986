import { call, put, take, takeLatest, delay } from 'redux-saga/effects';
import { apiCheckPreVerifyCustomer, apiCheckPreSettingCondition, apiSaveSurveysListSetting, apiUnregisterUser } from "../api/api";
import { FETCH_PRE_SETTING_CONDITION, checkPreSettingCondition, SAVE_SURVEYS_SETTING, UNREGISTER_USER, FETCH_PRE_VERIFY_CUSTOMER } from "../actions/settingAction";
import { showSpinner, hideSpinner } from "../components/common/AppProvider";
import { handleStatusError } from "./rootSaga";
import { safeNavigate } from '../utils/navigation';
import { workerCreateDeepLink } from './registerSaga';
import { logEvent } from '../utils/dataLayers/analytics';
import { errorMessages } from '../utils/errorCode';
import { calculateTimeToUnlock } from '../utils/isExpired';
import { purpose } from '../utils/purpose';
import { SagaIterator } from 'redux-saga';
import { pushFinishManageSurvey } from '../utils/dataLayers/manage_survay_layer';

export function* workerCheckPreSettingCondition(): SagaIterator {
  try {
    const response = yield call(apiCheckPreSettingCondition);
    if (response.success) {
      yield put(checkPreSettingCondition(response.data.isFillConsentAllowed, true));
    } else {
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  }
}

export function* watcherCheckPreSettingCondition(): SagaIterator {
  yield takeLatest(FETCH_PRE_SETTING_CONDITION, workerCheckPreSettingCondition);
}

function* workerSaveSurveysSetting(payload: any): SagaIterator {
  try {
    yield call(showSpinner);
    const { answers, id, selectedSurveyNames, mapIndexOfSelected } = payload
    const response = yield call(apiSaveSurveysListSetting, answers, id);
    if (response.success) {
      yield call(pushFinishManageSurvey, selectedSurveyNames, mapIndexOfSelected)
      yield delay(1500)
      yield call(safeNavigate, 'success-survey')
      yield call(hideSpinner);
    } else {
      yield call(handleStatusError, response.code);
      yield call(hideSpinner);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
    yield call(hideSpinner);
  }
}

export function* watcherSaveSurveysSetting(): SagaIterator {
  while (true) {
    const action = yield take(SAVE_SURVEYS_SETTING);
    yield call(workerSaveSurveysSetting, action.payload);
  }
}

function* workerUnregisterUser(payload: any): SagaIterator {
  try {
    yield call(showSpinner);
    const response = yield call(apiUnregisterUser);
    if (response.success) {
      localStorage.removeItem('scenarioId')
      logEvent({
        event: 'track_event',
        category: 'line_krungsri_simple_unbinding',
        action: 'unbinding_pop_up',
        label: 'unbinding_completed',
      })
      yield call(safeNavigate, 'success-unbinding')
      yield call(hideSpinner);
    } else {
      yield call(handleStatusError, response.code);
      yield call(hideSpinner);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
    yield call(hideSpinner);
  }
}

export function* watcherUnregisterUser(): SagaIterator {
  while (true) {
    const action = yield take(UNREGISTER_USER);
    yield call(workerUnregisterUser, action.payload);
  }
}

export function* workerVerifyCustomerCommonFlow(payload: any): SagaIterator {
  try {
    yield call(showSpinner);
    const { verifyBy } = payload
    const flow = sessionStorage.getItem('flow') || '';
    const response = yield call(apiCheckPreVerifyCustomer, purpose(flow));
    if (response.success) {
      if (verifyBy === 'kma') {
        localStorage.setItem('wantToGoTo', flow)
        yield call(workerCreateDeepLink)
      } else {
        yield call(hideSpinner);
        yield call(safeNavigate, 'receive-otp')
      }
    } else {
      yield call(hideSpinner);
      if (response.code === 'LKS0603') {
        const { title, description } = errorMessages["LKS0603"];
        safeNavigate('/locked-register', {
          header: 'ข้อมูลผู้สมัคร',
          iconSources: '/svg/alert.svg',
          title: title,
          desc: description,
          buttonText: 'กลับสู่ LINE',
          time: calculateTimeToUnlock(response.metadata.cooldownFinishedAt),
          onCountdownEnd: '/receive-otp'
        });
      } else if (response.code === 'LKS0604') {
        const { title, description } = errorMessages["LKS0604"];
        safeNavigate('/suspend-register', { header: title, description: description })
      } else {
        yield call(handleStatusError, response.code);
      }
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  }
}

export function* watcherVerifyCustomerCommonFlow(): SagaIterator {
  while (true) {
    const action = yield take(FETCH_PRE_VERIFY_CUSTOMER);
    yield call(workerVerifyCustomerCommonFlow, action.payload);
  }
}