export const EXCHANGE_LINE_TOKEN = 'EXCHANGE_LINE_TOKEN'
export const EXCHANGE_LINE_TOKEN_AFTER_KMA = 'EXCHANGE_LINE_TOKEN_AFTER_KMA'
export const EXCHANGE_LINE_TOKEN_BEFORE_STATEMENT = 'EXCHANGE_LINE_TOKEN_BEFORE_STATEMENT'
export const EXCHANGE_LINE_TOKEN_BEFORE_SETTING = 'EXCHANGE_LINE_TOKEN_BEFORE_SETTING'

export const exchangeLineToken = (lineToken: string) => ({
  type: EXCHANGE_LINE_TOKEN,
  payload: { lineToken }
});

export const exchangeLineTokenAfterKma = (lineToken: string) => ({
  type: EXCHANGE_LINE_TOKEN_AFTER_KMA,
  payload: { lineToken }
});

export const exchangeLineTokenBeforeSetting = (lineToken: string) => ({
  type: EXCHANGE_LINE_TOKEN_BEFORE_SETTING,
  payload: { lineToken }
});

export const exchangeLineTokenBeforeStatement = (lineToken: string) => ({
  type: EXCHANGE_LINE_TOKEN_BEFORE_STATEMENT,
  payload: { lineToken }
});