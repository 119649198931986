import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
});

export const handleApiError = (error: any) => {
    if (error.response) {
        return {
            success: false,
            code: error.response.data?.error.code,
            metadata: error.response.data?.error.metadata
        };

    } else if (error.request) {
        return {
            success: false,
            message: 'No response received from server',
        };
    } else {
        return {
            success: false,
            message: error.message || 'Error in sending request',
        };
    }
};