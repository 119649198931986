import React, { useEffect } from 'react';
import { useLineAuth } from '../../../hooks/useLineAuth';
import { showSpinner } from '../../../components/common/AppProvider';

const LandingPage: React.FC = () => {

    // useEffect(()=>{
    //     showSpinner()
    // },[])
    
    const { lineIdToken } = useLineAuth();
    return (
        <div style={{ backgroundColor: 'white', height: '100vh', width: '100vw' }}>
        </div>
    );
};

export default LandingPage;
