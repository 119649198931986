import React, { useState, ChangeEvent, useEffect } from "react";
import PageProvider from '../../../components/layout/PageProvider';
import Button from '../../../components/common/Button';
import ButtomSpan from "../../../components/layout/BottomSpan"
import { Title } from '../../../components/common/Texts';
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from '../../../actions/accountAction';
import { registerSelector } from "../../../reducers/registerReducer";
import { RootState } from "../../../reducers/rootReducer";
import CustomInput from "../../../components/common/CustomInput";
import { formatAccountNumber } from "../../../utils/formatInput";
import PopupAlert from "../../../components/common/popup/PopUpAlert";
import { hidePopupAlert, showPopupAlert } from "../../../components/common/AppProvider";
import { errorMessages } from "../../../utils/errorCode";
import { safeNavigate } from "../../../utils/navigation";
import { pushErrorFieldAppear, pushEvent1, pushEvent12, pushEvent2, pushEvent3, pushEvent4 } from "../../../utils/dataLayers/register_after_otp_layer";
import { getOSByPlatform } from "../../../utils/getOSByPlatform";

const AccountInfoPage: React.FC = () => {
    const dispatch = useDispatch()
    const { bankAccounts } = useSelector((state: RootState) => registerSelector(state));
    const [accountID, setAccountID] = useState<string>("");
    const [isAccountIDValid, setIsAccountIDValid] = useState(false);
    const [iconType, setIconType] = useState<number>(1);
    const [popUpTitle, setPopupTitle] = useState<string>("");
    const [popUpDescription, setPopupDescription] = useState<string>("");
    const [showPopup, setShowPopup] = useState(false);
    const [isKeyboard, setIsKeyboard] = useState<boolean>(false);

    const adjustHeight = () => {
        const height = window.innerHeight;
        if (height < 600) {
            setIsKeyboard(true)
        } else {
            setIsKeyboard(false)
        }
    };

    useEffect(() => {
        window.addEventListener('resize', adjustHeight);
        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, []);

    const handleAccountID = (e: ChangeEvent<HTMLInputElement>) => {
        setAccountID(e.target.value)
    };

    const validateAccountID = (value: string) => {
        if (!value) return 'กรุณากรอกเลขที่บัญชี';
        if (value.replace(/\D/g, '').length !== 10) return 'กรุณากรอกเลขที่บัญชี 10 หลักให้ถูกต้อง';
        return null;
    };

    const onClosePopup = () => {
        setShowPopup(false);
        if (popUpTitle) {
            switch (popUpTitle) {
                case errorMessages['LKS0503'].title:
                    pushEvent4('account_no_not_found');
                    break;
                case errorMessages['LKS0504'].title:
                    pushEvent4('account_not_support');
                    break;
                case errorMessages['LKS0505'].title:
                    pushEvent4('account_already_exists');
                    break;
                case errorMessages['LKS0506'].title:
                    pushEvent4('account_suspended');
                    break;
                default:
                    break;
            }
        }
    }

    const goToNextPage = () => {
        pushEvent1();
        dispatch(addProduct(accountID.replace(/-/g, ''), bankAccounts, true));
    };

    const goToPreviousPage = () => {
        safeNavigate('/info');
        pushEvent2();
    };

    const checkIsCanGoNext = () => {
        const addAccountError = validateAccountID(accountID);
        setIsAccountIDValid(!addAccountError)
        if (addAccountError) {
            switch (addAccountError) {
                case 'กรุณากรอกเลขที่บัญชี':
                    pushErrorFieldAppear('account_no', 'not_filled');
                    break;
                case 'กรุณากรอกเลขที่บัญชี 10 หลักให้ถูกต้อง':
                    pushErrorFieldAppear('account_no', 'not_correct');
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        if (showPopup && popUpTitle) {
            switch (popUpTitle) {
                case errorMessages['LKS0503'].title:
                    pushEvent3('account_no_not_found');
                    break;
                case errorMessages['LKS0504'].title:
                    pushEvent3('account_not_support');
                    break;
                case errorMessages['LKS0505'].title:
                    pushEvent3('account_already_exists');
                    break;
                case errorMessages['LKS0506'].title:
                    pushEvent3('account_suspended');
                    break;
                default:
                    break;
            }
        }
    }, [showPopup, popUpTitle]);

    return (
        <PageProvider>
            <Title style={{ marginBottom: 8 }}>กรอกข้อมูลบัญชี</Title>
            <CustomInput
                title='เลขที่บัญชี'
                placeholder='ตัวอย่าง: 1234512341'
                value={accountID}
                onChange={handleAccountID}
                type='tel'
                validate={validateAccountID}
                format={formatAccountNumber}
                onBlur={checkIsCanGoNext}
                validateOnInit={false}
            />
            <ButtomSpan>
                {!isKeyboard && (
                    <div>
                        <Button style={{ marginBottom: 24 }} text={'ถัดไป'} type={!isAccountIDValid ? 1 : 0} onClick={goToNextPage} />
                        <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
                    </div>
                )}
            </ButtomSpan>
            {
                showPopup && <PopupAlert
                    title={popUpTitle}
                    iconType={iconType}
                    description={popUpDescription}
                    ButtonClick={onClosePopup} />
            }
        </PageProvider>
    );
}

export default AccountInfoPage;