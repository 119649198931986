import React, { useState, useRef, useEffect } from 'react';
import Button from '../../../components/common/Button';
import CheckBox from '../../../components/common/CheckBox';
import { Title } from '../../../components/common/Texts';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTermsAndConditions, acceptTermsAndConditions } from '../../../actions/registerAction';
import { registerSelector } from '../../../reducers/registerReducer'
import { RootState } from '../../../store/store'
import Colors from '../../../assets/styles/Colors';
import { safeNavigate } from '../../../utils/navigation';
import { v4 as uuidv4 } from 'uuid';
import { pushEvent3 } from '../../../utils/dataLayers/before_register_layer';
import { Fonts } from '../../../assets/styles/Fonts';
import AppHeader from '../../../components/layout/AppHeader';
import { padding } from '../../../utils/padding';
import { logEvent } from '../../../utils/dataLayers/analytics';
import htmlToJsx from '../../../utils/htmlToJsx';

const TermsAndConditionsPage: React.FC = () => {

  const contentRef = useRef<HTMLDivElement>(null);

  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(
    () => JSON.parse(sessionStorage.getItem('isCheckboxEnabled') || 'false')
  );
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(
    () => JSON.parse(sessionStorage.getItem('isCheckboxChecked') || 'false')
  );

  const { terms, id } = useSelector((state: RootState) => registerSelector(state));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTermsAndConditions());
  }, [dispatch]);

  const goToNextPage = () => {
    const scenarioId = uuidv4();
    localStorage.setItem('scenarioId', scenarioId)
    dispatch(acceptTermsAndConditions(id));
    logEvent({
      event: 'track_event',
      category: 'line_krungsri_simple_register',
      action: 'click_button',
      label: 'next_term_condition',
    })
  };

  const goToPreviousPage = () => {
    safeNavigate('register');
    pushEvent3();
  };

  useEffect(() => {
    const element = contentRef.current;

    const handleScroll = () => {
      if (element) {
        const bottomReached = element.scrollTop + element.clientHeight >= element.scrollHeight - 10;
        if (bottomReached) {
          setIsCheckboxEnabled(true);
          sessionStorage.setItem('isCheckboxEnabled', JSON.stringify(true));
        }
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => element.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    const element = contentRef.current;

    if (element && isCheckboxEnabled) {
      if (element.scrollHeight > element.clientHeight) {
        element.scrollTop = element.scrollHeight;
      }
    }
  }, [isCheckboxEnabled]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsCheckboxChecked(isChecked);
    sessionStorage.setItem('isCheckboxChecked', JSON.stringify(isChecked));
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (contentRef.current) {
      const scrollbarHeight = '97px'
      const scrollTop = e.currentTarget.scrollTop;
      const scrollHeight = e.currentTarget.scrollHeight;
      const clientHeight = e.currentTarget.clientHeight;

      const scrollRatio = scrollTop / (scrollHeight - clientHeight);

      const scrollbarTop = Math.min(scrollRatio * (clientHeight - parseInt(scrollbarHeight)), clientHeight - parseInt(scrollbarHeight));

      const scrollbar = document.getElementById('custom-scrollbar-thumb');
      if (scrollbar) {
        scrollbar.style.top = `${scrollbarTop}px`;
        scrollbar.style.height = scrollbarHeight;
      }
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      handleScroll({ currentTarget: contentRef.current } as React.UIEvent<HTMLDivElement>);
    }
  }, [terms]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      margin: '0 auto',
      maxWidth: '450px',
      touchAction: 'pan-x pan-y',
    }}>
      <AppHeader />
      <Title style={{ padding: '16px 16px 0px 16px', }}>ข้อกำหนดและเงื่อนไขการใช้บริการ LINE Krungsri Simple</Title>

      <div style={{
        flex: 1,
        overflowY: 'auto',
        padding: '16px',
        position: 'relative',
      }}>
        <div
          ref={contentRef}
          onScroll={handleScroll}
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            paddingRight: '12px',
            boxSizing: 'border-box',
            touchAction: 'pan-x pan-y',
          }}
          className="scroll-container"
        >
          <div style={{
            color: Colors.PRIMARY_TEXT_COLOR,
            textOverflow: 'ellipsis',
            fontFamily: Fonts.Thongterm_Medium,
            fontSize: '16px',
            lineHeight: '29px',
          }}
    >
      {htmlToJsx(terms)}
    </div>
        </div>

        <div
          id="custom-scrollbar-thumb"
          style={{
            position: 'absolute',
            right: '16px',
            top: '0',
            width: '7px',
            backgroundColor: Colors.LIGHT_GREY,
            borderRadius: '3px',
            pointerEvents: 'none',
          }}
        />
      </div>

      <div style={{
        backgroundColor: Colors.WHITE,
        padding: padding,
        textAlign: 'center',
      }}>
        <div style={{marginBottom: '24px'}}>
        <CheckBox
            checked={isCheckboxChecked}
            disabled={!isCheckboxEnabled}
            onChange={handleCheckboxChange}
            text={'ยอมรับข้อกำหนดและเงื่อนไข'}
          />
        </div>
        
        <Button text={'ถัดไป'} type={isCheckboxChecked ? 0 : 1} onClick={goToNextPage} />
        <div style={{ marginBottom: 24 }} />
        <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
      </div>

    </div>

  );
};

export default TermsAndConditionsPage;
