import React, { useEffect, useMemo, useRef } from "react";
import PageProvider from "../../../components/layout/PageProvider";
import ButtomSpan from "../../../components/layout/BottomSpan";
import Button from "../../../components/common/Button";
import { Title } from "../../../components/common/Texts";
import { useDispatch, useSelector } from "react-redux";
import { exchangeLineTokenBeforeStatement } from "../../../actions/tokenAction";
import { useLineAuth } from "../../../hooks/useLineAuth";
import { registerSelector } from "../../../reducers/registerReducer";
import { RootState } from "../../../reducers/rootReducer";
import { fetchPreVerifyCustomer } from "../../../actions/settingAction";
import {
  showPopupVerify,
  hidePopupVerify,
  hideSpinner,
  showSpinner,
} from "../../../components/common/AppProvider";
import { logEvent } from "../../../utils/dataLayers/analytics";
import {
  setAuthenticationReference,
  stampStartLiffStatement,
  verifyDeepLink,
} from "../../../actions/registerAction";
import { v4 as uuidv4 } from "uuid";
import { featureFlagSelector } from "../../../reducers/featureFlagReducer";
import { safeNavigate } from "../../../utils/navigation";
import { useLocation } from "react-router-dom";
import { statementSelector } from "../../../reducers/statementReducer";

const getGaClientId = () => {
  const match = document.cookie.match(/_ga=([^;]+)/);
  return match ? match[1] : null;
};

const PreStatementPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { authenticationApplicationUri, authenticationCount, isStartLiff } = useSelector(
    (state: RootState) => registerSelector(state)
  );
  const { isActive } = useSelector((state: RootState) => statementSelector(state)
  );
  const { enableSettingKma } = useSelector((state: RootState) => featureFlagSelector(state));
  const pushEvent = useRef(false);

  const { uuid, lineIdToken } = useLineAuth();
  const lineGaClientId = getGaClientId();
  const flow = sessionStorage.getItem("flow");
  const scenarioId = uuidv4();
  const params = new URLSearchParams(location.search);
  const accountRef = params.get('accountRef');
  var sha256 = require("crypto-js/sha256");

  const customDimension = useMemo(() => {
    if (lineGaClientId && uuid) {
      const hashUUID = (uuid: string): string => {
        return sha256(uuid).toString();
      };
      return {
        line_ga_client_id: lineGaClientId,
        line_uuid: hashUUID(uuid),
      };
    }
    return null;
  }, [lineGaClientId, uuid]);

  useEffect(() => {
    if (authenticationApplicationUri) {
      console.log('qewrwerqw')
      window.location.href = authenticationApplicationUri;

      const timeoutId = setTimeout(() => {
        if (document.visibilityState === "visible") {
          logEvent({
            event: "track_event",
            category: "line_krungsri_simple_request_statement_error_pop_up",
            action: "verify_error",
            label: "not_found_kma_app",
          });
          showPopupVerify({
            title: "ไม่พบ krungsri app บนอุปกรณ์นี้",
            description:
              "คุณต้องการยืนยันตัวตนด้วยรหัสผ่านครั้งเดียว (OTP) \n เพื่อสมัครบริการ Krungsri Simple หรือไม่",
            primaryButtonText: "ยืนยันด้วย​ OTP",
            primaryButtonClick: () => {
              dispatch(setAuthenticationReference("", "", "", 0));
              sessionStorage.setItem("isHaveKMA", JSON.stringify(false));
              dispatch(fetchPreVerifyCustomer("otp"));
              hidePopupVerify();
              hideSpinner();
            },
            secondaryButtonClick: () => {
              hidePopupVerify();
              hideSpinner();
            },
          });
        } else {
          safeNavigate('auth-kma-verification')
          hideSpinner()
        }
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [authenticationCount]);

  useEffect(() => {
    if (isStartLiff && lineIdToken) {
      showSpinner()
      sessionStorage.setItem("flow", "select-statement");
      localStorage.setItem("scenarioId", scenarioId);
      localStorage.setItem('accountRef', `${accountRef}`);
      dispatch(exchangeLineTokenBeforeStatement(lineIdToken));
      dispatch(stampStartLiffStatement())
    }
  }, [lineIdToken]);

  useEffect(() => {
    if (!pushEvent.current && customDimension) {
      logEvent({
        event: "track_event",
        category: "line_krungsri_simple_request_statement",
        action: "land_to_verify_page",
        label: "krungsri_simple_service",
        customDimensions: customDimension,
      });
      pushEvent.current = true;
    }
  }, [flow, customDimension]);

  const goToNextPageWithKMA = () => {
    sessionStorage.setItem("requestStatementByKMA", JSON.stringify(true));
    dispatch(fetchPreVerifyCustomer("kma"));
    logEvent({
      event: "track_event",
      category: "line_krungsri_simple_request_statement",
      action: "click_button",
      label: "verified_by_kma",
    });
  };
  const goToNextPageWithOTP = () => {
    sessionStorage.setItem("requestStatementByOTP", JSON.stringify(true));
    dispatch(setAuthenticationReference("", "", "", 0));
    dispatch(fetchPreVerifyCustomer("otp"));
    logEvent({
      event: "track_event",
      category: "line_krungsri_simple_request_statement",
      action: "click_button",
      label: "not_verified_by_kma",
    });
  };

  return (
    <> {isActive && (
      <PageProvider>
        <div style={{ marginTop: 217, textAlign: "center" }}>
          <img src={"/svg/bayIcon.svg"} alt="bay icon" style={{ width: 56, height: 56, flexShrink: 0, marginBottom: 24 }} />
          <Title style={{ fontSize: 18, lineHeight: "30px" }}>กรุณายืนยันตัวตนเพื่อทำรายการ</Title>
        </div>
        <ButtomSpan>
          {enableSettingKma && <Button style={{ marginBottom: 24 }} text={"ยืนยันด้วย krungsri app"} type={0} onClick={goToNextPageWithKMA} />}
          <Button text={"ไม่มี krungsri app"} type={2} onClick={goToNextPageWithOTP} />
        </ButtomSpan>
      </PageProvider>
    )}
    </>

  );
};

export default PreStatementPage;
