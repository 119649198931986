import { getOSByPlatform } from '../utils/getOSByPlatform';
import { x_purpose } from '../utils/purpose';
import { api, handleApiError } from './apiHelper';

export const apiGetVerify = async (lineIdToken: string) => {
  try {
    const response = await api.post('/services/lks/inter/api/v1/verify-token', {
      "lineIdToken": lineIdToken,
    });
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGetTermsAndConditions = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  try {
    const response = await api.get('/services/lks/inter/api/v1/term-and-conditions', {
      maxBodyLength: Infinity,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiAcceptTermsAndConditions = async (id: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await api.post('/services/lks/inter/api/v1/term-and-conditions/accept',
      {
        acceptedId: id
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'ACCEPT_TERM_AND_CONDITION',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiSaveUserInfo = async (identificationNumber: string, mobileNumber: string, referral: string, deviceOS: string, reCaptchaToken: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOsHeader = getOSByPlatform()
  const isHaveKMA = JSON.parse(sessionStorage.getItem('isHaveKMA') || 'false')

  let endpoint = '/services/lks/inter/api/v1/registers/otp/pre-register';
  if (isHaveKMA) {
    endpoint = '/services/lks/inter/api/v1/registers/kma/pre-register';
  }
  try {
    const response = await api.post(endpoint,
      {
        "identificationNumber": identificationNumber,
        "mobileNumber": mobileNumber,
        "referral": referral,
        "recaptchaToken": reCaptchaToken,
        "deviceOS": deviceOS
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': isHaveKMA ? 'PRE_REGISTER_KMA' : 'PRE_REGISTER_OTP',
          'X-Device-Os': deviceOsHeader,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    //   const response = {data:{
    //     "error": {
    //         "code": "LKS0407",
    //         "message": "user has been banned",
    //         "detailedErrors": null,
    //         "metadata": {
    //             "profileStatus": "LOCKED"
    //         }
    //     }
    // }}
    // const response = {
    //   data: {
    //     "registerRef": "4a2c7920-1b36-4393-9c4f-4745c7943599",
    //     "mustConfirmRegisterForReplace": true
    //   }
    // }
    return { success: true, data: response.data };
    // return handleApiError(response.data.error);
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGenerateOtp = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/otp/request',
      {
        "registerRef": registerRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_CREATE_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiVerifyOtp = async (otpRef: string, otpCode: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/otp/verify',
      {
        "otpRef": otpRef,
        "otpCode": otpCode,
        "registerRef": registerRef,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};


export const apiCreateDeepLink = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/kma/deep-link/request',
      {
        "registerRef": registerRef,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_CREATE_KMA',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    // const response = {
    //   data: {
    //     "authenticationReference": "091f1ddf-2b3d-4500-8562-0a6f4323a138",
    //     "authenticationWebUri": "https://www.krungsrimobile.com/BAY.KMA/DeepLink.Service/UA/DeepLinkCampaign/",
    //     "authenticationApplicationUri": "krungsri-ndid://dvp?tokenId=123456789",
    //     "expiryDateTime": "2024-12-07T13:45:12.123+07:00"
    //   }
    // }
    //   const response = {data:{
    //     "error": {
    //         "code": "LKS0302",
    //         "message": "คุณไม่ได้สมัครใช้ krungsri app คุณต้องการยืนยันตัวตนด้วยรหัสผ่านครั้งเดียว (OTP) เพื่อสมัครบริการ Krungsri Simple หรือไม่",
    //         "detailedErrors": null
    //     }
    // }}
    // return handleApiError(response.data.error);
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiVerifyDeepLink = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  const authRef = sessionStorage.getItem('authRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/kma/deep-link/verify',
      {
        "registerRef": registerRef,
        "authRef": authRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_KMA',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    // await new Promise((resolve) => setTimeout(resolve, 500));
    // const response = {
    //   data: {
    //     "bankAccounts": [
    //       {
    //         "accountNumber": "7777777777",
    //         "accountType": "SAVING"
    //       },
    //       {
    //         "accountNumber": "8888888888",
    //         "accountType": "CURRENT"
    //       }
    //     ]
    //   }
    // }

    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiSubmitRegister = async (accounts: []) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const registerRef = localStorage.getItem('registerRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/submit',
      {
        "registerRef": registerRef,
        "registerAccounts": accounts
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SUBMIT_REGISTRATION',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiAddAccount = async (accountNumber: string) => {
  const registerRef = localStorage.getItem('registerRef');
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await api.post('/services/lks/inter/api/v1/bank-accounts/soft-add',
      {
        "registerRef": registerRef,
        "accountNumber": accountNumber
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SOFT_ADD_ACCOUNT',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Register',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiDeleteAccount = async (accountId: string) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await api.post('/services/lks/inter/api/v1/bank-accounts/soft-delete',
      {
        "accountId": accountId
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SOFT_DELETE_ACCOUNT',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Manage Account',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGetSurveysList = async (tokenType: string) => {
  const token = sessionStorage.getItem(`${tokenType}`);
  try {
    const response = await api.get('/services/lks/inter/api/v1/active/surveys',
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGetUserSurvey = async () => {
  const accessToken = sessionStorage.getItem('accessToken');

  try {
    const response = await api.get('/services/lks/inter/api/v1/active/survey-submissions',
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiSaveSurveysList = async (answers: string[], id: string) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await api.put(`/services/lks/inter/api/v1/survey-submissions/${id}`,
      {
        "answers": answers
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SUBMIT_MARGETING_SURVEY',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Add Marketing Survey',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiSaveSurveysListSetting = async (answers: string[], id: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await api.put(`/services/lks/inter/api/v1/survey-submissions/${id}`,
      {
        "answers": answers
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'SUBMIT_MARGETING_SURVEY',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Manage Marketing Survey',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiGetAccountDetail = async () => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const accountRef = localStorage.getItem('accountRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/bank-accounts/account-details/statement',
      {
        "accountRef": accountRef,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGenerateStatement = async (
  language: string,
  fromBookingDateTime: string,
  toBookingDateTime: string,
  email: string,
) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const accountRef = localStorage.getItem('accountRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/statements',
      {
        "accountRef": accountRef,
        "Language": language,
        "FromBookingDateTime": fromBookingDateTime,
        "ToBookingDateTime": toBookingDateTime,
        "Email": email,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'REQUEST_STATEMENT',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Request Statement',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGenerateOtpCommon = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await api.post('/services/lks/inter/api/v1/verification/otp/request',
      {},
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_CREATE_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiVerifyOtpCommon = async (otpRef: string, otpCode: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';
  try {
    const response = await api.post('/services/lks/inter/api/v1/verification/otp/verify',
      {
        "otpRef": otpRef,
        "otpCode": otpCode,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGetConsentsList = async (excludeAccepted: boolean) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';

  const url = excludeAccepted
    ? '/services/lks/inter/api/v1/consents?excludeAccepted=true'
    : '/services/lks/inter/api/v1/consents';
  try {
    const response = await api.get(url,
      {
        headers: {
          'Authorization': `Bearer ${verifyToken}`,
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );

    return { success: true, data: response };
  } catch (error) {
    return handleApiError(error);
  }
};


export const apiSaveConsentsList = async (consents: any) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await api.put('/services/lks/inter/api/v1/consents',
      {
        "consents": consents
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'ACCEPT_CONSENT',
          'X-Device-Os': deviceOS,
          'X-Purpose': flow === 'register' ? 'Accept Consent' : 'Manage Consent',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiCheckPreSettingCondition = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await api.post('/services/lks/inter/api/v1/settings/check-pre-setting',
      {

      }
      , {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'CHECK_PRE_SETTING',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Manage',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiUnregisterUser = async () => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await api.post('/services/lks/inter/api/v1/un-registers',
      {},
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'UNREGISTRATION',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Unregister',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiGetBankAccount = async () => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  try {
    const response = await api.get('/services/lks/inter/api/v1/bank-accounts',
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiSoftAddAccount = async (accountNumber: string) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await api.post('/services/lks/inter/api/v1/bank-accounts/soft-add',
      {
        "accountNumber": accountNumber
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SOFT_ADD_ACCOUNT',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiSubmitBankAccount = async (accounts: []) => {
  const verifyToken = sessionStorage.getItem('verifyToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  try {
    const response = await api.post('/services/lks/inter/api/v1/bank-accounts/submit',
      {
        "accounts": accounts,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`,
          'X-Action': 'SUBMIT_ACCOUNT',
          'X-Device-Os': deviceOS,
          'X-Purpose': 'Manage Account',
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiKmaRequest = async (purpose: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const accountRef = localStorage.getItem('accountRef') || "";
  const flow = sessionStorage.getItem('flow') || '';
  try {
    const response = await api.post('/services/lks/inter/api/v1/verification/kma/request',
      {
        "redirectPath": '/auth-kma-verification',
        "purpose": purpose,
        "accountReference": accountRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_CREATE_KMA',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiKmaVerify = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const authRef = sessionStorage.getItem('authRef');
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await api.post('/services/lks/inter/api/v1/verification/kma/verify',
      {
        "authRef": authRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'AUTHENTICATION_KMA',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    // await new Promise((resolve) => setTimeout(resolve, 500));
    // const response = {
    //   data: {
    //     "bankAccounts": [
    //       {
    //         "accountNumber": "7777777777",
    //         "accountType": "SAVING"
    //       },
    //       {
    //         "accountNumber": "8888888888",
    //         "accountType": "CURRENT"
    //       }
    //     ]
    //   }
    // }

    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiCheckPreVerifyCustomer = async (purpose: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await api.post('/services/lks/inter/api/v1/verification/check-pre-verify-customer',
      {
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'CHECK_PRE_VERIFY_CUSTOMER',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiVerifyCustomer = async (mobileNumber: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await api.post('/services/lks/inter/api/v1/verification/verify-customer',
      {
        "mobileNumber": mobileNumber,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'VERIFY_CUSTOMER',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};


export const apiGetValidateCaptcha = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';

  try {
    const response = await api.get('/services/lks/inter/api/v1/registers/validate-captcha',
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiCheckOtpAvailable = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  const scenarioId = localStorage.getItem('scenarioId') || '';
  const deviceOS = getOSByPlatform()
  const flow = sessionStorage.getItem('flow') || '';

  try {
    const response = await api.post('/services/lks/inter/api/v1/verification/otp/check-generate-available',
      {
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Action': 'CHECK_GENERATE_OTP',
          'X-Device-Os': deviceOS,
          'X-Purpose': x_purpose(flow),
          'X-Scenario-Id': `${scenarioId}`,
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};
