import { api, handleApiError } from "./apiHelper";

export const apiValidateUserStatusFromAccountId = async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    const accountRef = localStorage.getItem('accountRef');
    try {
      const response = await api.get(`/services/lks/inter/api/v1/statements/pre-generate-validate?bank_acc_id=${accountRef}`, {
        maxBodyLength: Infinity,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleApiError(error);
    }
  };
