import React from 'react';
import Colors from '../../assets/styles/Colors';

interface DotProps {
    animationDelay: string;
}

const Dot: React.FC<DotProps> = ({ animationDelay }) => {
    const dotStyle: React.CSSProperties = {
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        backgroundColor: Colors.PRIMARY_KS_YELLOW,
        margin: '0 8px',
        animation: 'dotPulse 1.2s infinite ease-in-out',
        animationDelay,
    };

    const keyframesStyle = `
        @keyframes dotPulse {
            0%, 100% {
                transform: scale(1);
            }
            50% {
                transform: scale(2);
            }
            75% {
                transform: scale(4);
            }
        }
    `;

    return (
        <>
            <style>{keyframesStyle}</style>
            <div style={dotStyle}></div>
        </>
    );
};

export default Dot;
