import React, { useEffect, useRef } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Description, Title } from '../../../components/common/Texts';
import liff from '@line/liff';
import { pushEvent7, pushEvent8 } from '../../../utils/dataLayers/before_register_layer';
import { useLocation } from 'react-router-dom';
import { errorMessages } from '../../../utils/errorCode';
import { logEvent } from '../../../utils/dataLayers/analytics';

const SuspendPage: React.FC = () => {
    const effectRan = useRef(false);
    const flow = sessionStorage.getItem('flow');

    const mapHeaderParams = (headerParam: string | null) => {
        switch (headerParam) {
            case errorMessages['LKS0604'].title:
                return 'verified_lock_suspended';
            case errorMessages['LKS0603'].title:
                return 'verified_lock_1h';
            case errorMessages['LKS0406'].title:
                return 'profile_lock';
            default:
                break;
        }
    };

    useEffect(() => {
        if (!effectRan.current) {
            switch (headerParams) {
                case errorMessages['LKS0604'].title:
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_service_otp_error_pop_up',
                        action: 'otp_error',
                        label: 'verified_lock_suspended',
                    })
                    break;
                case errorMessages['LKS0407'].title:
                        logEvent({
                            event: 'track_event',
                            category: 'line_krungsri_simple_register',
                            action: 'profile_lock',
                            label: 'suspended',
                        })
                        break;
                default:
                    pushEvent8();
                    break;
            }
            effectRan.current = true;
        }
    }, []);

    const callPhoneNumber = () => {
        liff.openWindow({
            url: 'tel:1572',
            external: true,
        });
        switch (headerParams) {
            case errorMessages['LKS0604'].title:
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_service_otp_error_pop_up',
                    action: 'click_button',
                    label: 'return_to_line',
                    error: 'verified_lock_suspended',
                })
                break;
            default:
                break;
        }
    };

    const closeLiffWindow = () => {
        liff.closeWindow();
        if (flow === 'register') {
            if (errorMessages['LKS0407'].title) {
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_error_pop_up',
                    action: 'click_button',
                    label: 'return_to_line',
                    error: 'profile_lock_suspended'
                })
            } else {
                pushEvent7('line_krungsri_simple_error_pop_up', 'profile_lock_suspended');
            }
        } else if (flow === 'otp') {
            logEvent({
                event: 'track_event',
                category: 'line_krungsri_simple_service_otp_error_pop_up',
                action: 'click_button',
                label: 'return_to_line',
                error: mapHeaderParams(headerParams)
            })
        }
    };

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const headerParams = params.get("header")
    const descriptionParams = params.get("description")

    return (
        <PageProvider>
            <Title>ข้อมูลผู้สมัคร</Title>
            <div style={{
                margin: '0 auto',
                textAlign: 'center',
                paddingTop: '11vh',
            }}>
                <img src={'/svg/suspend.svg'} alt="Popup Icon" style={{
                    width: '88px',
                    height: '88px',
                    marginBottom: '40px',
                }} />
                <Title style={{ fontSize: '24px', lineHeight: '36px', marginBottom: '8px' }}>{headerParams ? headerParams : 'การสมัครบริการของคุณถูกระงับ'}</Title>
                <Description>{descriptionParams ? descriptionParams : `คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาติดต่อสาขาธนาคารกรุงศรี ก่อนสมัครบริการอีกครั้ง`}</Description>
            </div>

            <ButtomSpan>
                <Button text={'กลับสู่ LINE'} onClick={closeLiffWindow} type={0} />
            </ButtomSpan>
        </PageProvider>
    );
};

export default SuspendPage;
