import liff from "@line/liff";
import { useState, useEffect, useRef } from "react";
import { safeNavigate } from "../utils/navigation";

export const useLineAuth = () => {
  const [uuid, setUuid] = useState('');
  const [lineIdToken, setToken] = useState('');
  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    const fetchTokenAndProfile = async () => {
      const liffId = process.env.REACT_APP_LIFF_ID;

      if (!liffId) {
        return;
      }

      try {
        await liff.init({ liffId });
        const getTokenWithRetry = async (retries = 3) => {
          let idToken = liff.getIDToken();
          let decodedIDToken = liff.getDecodedIDToken();

          while ((!idToken || !decodedIDToken) && retries > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            idToken = liff.getIDToken();
            decodedIDToken = liff.getDecodedIDToken();
            retries--;
          }
          return { idToken, decodedIDToken };
        };
        const { idToken, decodedIDToken } = await getTokenWithRetry();
        if (idToken) {
          setToken(idToken);
        }
        if (decodedIDToken?.sub) {
          setUuid(decodedIDToken?.sub);
        }
      } catch (error) {
        safeNavigate('general-error')
      }
    };

    fetchTokenAndProfile();
  }, []);

  return { uuid, lineIdToken };
};
