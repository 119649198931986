import { networkReducer } from './networkReducer';
import { combineReducers } from 'redux';
import { registerReducer } from './registerReducer'
import { accountReducer } from './accountReducer';
import { settingReducer } from './settingReducer';
import { featureFlagReducer } from './featureFlagReducer';
import { statementReducer } from './statementReducer';

const rootReducer = combineReducers({
  register: registerReducer,
  account: accountReducer,
  setting: settingReducer,
  network: networkReducer,
  featureFlags: featureFlagReducer,
  statement: statementReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
