import React, { useEffect, useRef, useState } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import AccountInfo from '../../../components/common/AccountInfo';
import Button from '../../../components/common/Button';
import ToggleDropdown from '../../../components/common/ToggleDropDown';
import { Title } from '../../../components/common/Texts';
import { useDispatch, useSelector } from 'react-redux';
import { registerSelector } from '../../../reducers/registerReducer';
import { RootState } from '../../../reducers/rootReducer';
import { setBankAccounts, submitBankAccount, submitRegister } from '../../../actions/registerAction';
import { hidePopupAlert, showPopupAlert } from '../../../components/common/AppProvider';
import { errorMessages } from '../../../utils/errorCode';
import { safeNavigate } from '../../../utils/navigation';
import { pushEvent6, pushEvent7 } from '../../../utils/dataLayers/register_after_otp_layer';
import { logEvent } from '../../../utils/dataLayers/analytics';

const ConfirmAccountPage: React.FC = () => {
    const dispatch = useDispatch();
    const { bankAccounts } = useSelector((state: RootState) => registerSelector(state));
    const [buttonType] = useState<number>(2);
    const [accounts, setAccounts] = useState(bankAccounts);
    const nextPageEventPushed = useRef(false);
    const flow = sessionStorage.getItem('flow');
    const hashedMobileNumber = localStorage.getItem('hashedMobileNumber');

    useEffect(() => {
        setAccounts(bankAccounts);
    }, [bankAccounts]);

    const handleToggle = (accountNumber: string) => {
        const updatedAccounts = accounts.map((account) => {
            if (account.accountNumber === accountNumber) {
                const isNotificationEnabled = !account.isNotificationEnabled;
                if (isNotificationEnabled && flow === 'confirm-account') {
                    logEvent({
                        event: 'track_event',
                        category: 'line_krungsri_simple_manage_account',
                        action: 'turn_on',
                        label: 'notification',
                    });
                } else if (isNotificationEnabled && flow === 'register') {
                    pushEvent6();
                }
                return { ...account, isNotificationEnabled }; // turn on notification
            }
            return account;
        });


        setAccounts(updatedAccounts);
        dispatch(setBankAccounts(updatedAccounts));
    };

    const handleAddProduct = () => {
        if (bankAccounts.length < 15) {
            safeNavigate('/add-account')
            if (flow == 'confirm-account') {
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_manage_account',
                    action: 'click_button',
                    label: 'add_more_account',
                })
            } else {
                pushEvent7();
            }
        } else {
            const { title, description } = errorMessages["LKS0502"];
            showPopupAlert({
                title: title,
                description: description,
                iconType: 1,
                ButtonText: 'ตกลง',
                ButtonClick: () => hidePopupAlert(),
            })
        }
    }
    const goToNextPage = () => {
        const accountNotiLength = accounts.filter(account => account.isNotificationEnabled).length;

        if (!nextPageEventPushed.current) {
            nextPageEventPushed.current = true;

            if (flow === 'confirm-account') {
                dispatch(submitBankAccount(accounts));
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_manage_account',
                    action: 'click_button',
                    label: 'confirm_add_account',
                    customDimensions: {
                        account_count: accountNotiLength,
                    }
                })
            } else {
                const filteredAccountsRegister = accounts.map(({ accountType, accountNumber, isNotificationEnabled }) => ({
                    accountType,
                    accountNumber,
                    isNotificationEnabled,
                }));
                dispatch(submitRegister(filteredAccountsRegister));
                logEvent({
                    event: 'track_event',
                    category: 'line_krungsri_simple_account',
                    action: 'click_button',
                    label: 'confirm_add_account',
                    customDimensions: {
                        account_count: accountNotiLength,
                        user_phone: hashedMobileNumber,
                    }
                })
            }
        }
    };

    return (
        <PageProvider>
            <div>
                <Title>{flow === 'confirm-account' ? 'จัดการผลิตภัณฑ์' : 'ยืนยันผลิตภัณฑ์ที่รับบริการ'}</Title>
                <div style={{ marginTop: 8 }} />
                <ToggleDropdown shortText={"เพื่อความปลอดภัยสูงสุด กรุณาหลีกเลี่ยงการ"} longText={"เพื่อความปลอดภัยสูงสุด กรุณาหลีกเลี่ยงการใช้งานผ่านอุปกรณ์สาธารณะ ทั้งนี้ ระบบจะไม่สามารถหักเงินจากบัญชีธนาคารผ่านบริการนี้ได้"} />
                <div style={{ marginTop: 8 }} />
                <div style={{ marginTop: 8, marginBottom: 16, height: 1, background: '#E8E8E8' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Title>บัญชีเงินฝาก</Title>
                    <Title style={{ marginLeft: 'auto', fontSize: '14px' }}>เปิด-ปิดแจ้งเตือน</Title>
                </div>
                <div>
                    {accounts.slice(0, 15).map((account) => (
                        <AccountInfo
                            key={account.accountNumber}
                            accountNo={account.accountNumber}
                            type={account.accountType}
                            isNotificationEnabled={account.isNotificationEnabled}
                            accountStatus={account.accountStatus}
                            isKMA={false}
                            onToggle={() => handleToggle(account.accountNumber)}
                        />
                    ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
                    <Button
                        text={
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={'/svg/plus.svg'}
                                    alt="add product"
                                />
                                <span style={{ marginLeft: '8px' }}>เพิ่มบัญชี</span>
                            </span>
                        }
                        type={buttonType === 1 ? 1 : 2}
                        onClick={handleAddProduct}
                    />
                </div>
            </div>
            <div style={{ paddingTop: '120px' }} />
            <div
                style={{
                    position: 'fixed',
                    left: '-1px',
                    bottom: '0',
                    width: '100%',
                    height: '108px',
                    background: '#FFFFFF',
                    boxShadow: '1px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Button text={flow === 'confirm-account' ? 'บันทึก' : 'สมัครบริการ'} onClick={goToNextPage} type={0} />
            </div>
        </PageProvider>
    );
};

export default ConfirmAccountPage;
