import liff from "@line/liff";
import { useState, useEffect } from "react";
import { hideSpinner, showSpinner } from "../components/common/AppProvider";
import { safeNavigate } from "../utils/navigation";

export const useLineAuthFirstTime = () => {
  const [uuid, setUuid] = useState('');
  const [lineIdToken, setToken] = useState('');

  useEffect(() => {
    const fetchTokenAndProfile = async () => {
      showSpinner()
      const liffId = process.env.REACT_APP_LIFF_ID;

      if (!liffId) {
        safeNavigate('general-error')
        return;
      }

      try {
        await liff.init({ liffId });
        const getTokenWithRetry = async (retries = 3) => {
          let idToken = liff.getIDToken();
          let decodedIDToken = liff.getDecodedIDToken();

          while ((!idToken || !decodedIDToken) && retries > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            idToken = liff.getIDToken();
            decodedIDToken = liff.getDecodedIDToken();
            retries--;
          }
          return { idToken, decodedIDToken };
        };

        const { idToken, decodedIDToken } = await getTokenWithRetry();

        if (idToken) {
          setToken(idToken);
        }
        if (decodedIDToken?.sub) {
          setUuid(decodedIDToken?.sub);
        }
      } catch (error) {
        safeNavigate('general-error')
      } finally {
        hideSpinner();
      }
    };

    fetchTokenAndProfile();
  }, []);

  return { uuid, lineIdToken };
};