import { call, put, take } from 'redux-saga/effects';
import { handleStatusError } from './rootSaga';
import { SagaIterator } from 'redux-saga';
import { apiGetFeatureFlags } from '../api/featureFlagApi';
import { FETCH_FEATURE_FLAGS, setFeatureFlags } from '../actions/featureFlagsAction';

export function* workerFetchFeatureFlags(): SagaIterator {
  try {
    const response = yield call(apiGetFeatureFlags);
    if (response.success) {
      yield put(setFeatureFlags(response.data));
    } else {
      yield call(handleStatusError, response.code);
    }
  } catch (error) {
    yield call(handleStatusError, JSON.stringify(error));
  }
}

export function* watcherFetchFeatureFlags(): SagaIterator {
  while (true) {
    yield take(FETCH_FEATURE_FLAGS);
    yield call(workerFetchFeatureFlags);
  }
}
